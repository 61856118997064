<script setup>
import { ref } from "vue";
import { Link, router } from "@inertiajs/vue3";

const props = defineProps({
    profile: Object,
    filters: Object,
    ranks: Array,
    lines: Array,
});
const profileProp = ref(props.profile);
const rankProp = ref(props.ranks);
const lineProp = ref(props.lines);

const facultyProfiles = profileProp.value.data;
const paginateProfiles = profileProp.value.links;
const ranksProp = rankProp.value;
const linesProp = lineProp.value;

const ranks = _.orderBy(ranksProp, "sort_order", "asc");
const lines = _.orderBy(linesProp, "sort_order", "asc");

const rankOrder = _.uniqBy(ranks, "level").map((rank) => rank.level);
const lineOrder = _.uniqBy(lines, "name").map((line) => line.name);

const search = ref(props.filters.search || "");
const selectedRank = ref(props.filters.rank || "");
const selectedLine = ref(props.filters.line || "");

const updateFilters = () => {
    const queryParams = new URLSearchParams();

    if (search.value) {
        queryParams.append("search", search.value);
    }
    if (selectedRank.value !== "") {
        queryParams.append("rank", selectedRank.value);
    }
    if (selectedLine.value !== "") {
        queryParams.append("line", selectedLine.value);
    }

    router.get(`/researchprofiles?${queryParams.toString()}`, {
        preserveState: true,
        replace: true,
    });
};
</script>

<template>
    <div class="bg-gray-100">
        <div class="py-12">
            <div class="max-w-screen-xl mx-auto sm:px-6 lg:px-8">
                <div
                    class="grid md:grid-cols-2 gap-4 bg-white overflow-hidden shadow-sm sm:rounded-lg sm:grid-cols-2 sm:grid-row-2"
                >
                    <input
                        v-model="search"
                        type="text"
                        placeholder="Search by Faculty Name"
                        class="p-6 bg-white border-0 focus:border-2 focus:border-cardinal-red focus:rounded-l-lg text-lg focus:outline-none focus:ring-0"
                        @keyup.enter="updateFilters(search)"
                    />

                    <div class="p-2 pr-8">
                        <label
                            class="block mb-2 text-sm font-medium text-gray-900"
                            >Select Rank</label
                        >
                        <select
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-cardinal-red focus:outline-none focus:ring-0 block w-full p-2.5"
                            v-model="selectedRank"
                            @change="updateFilters"
                        >
                            <option value="">All Rank Levels</option>
                            <option
                                v-for="rank in rankOrder"
                                :value="rank"
                                :key="rank"
                            >
                                {{ rank }}
                            </option>
                        </select>
                    </div>

                    <!-- <div class="p-2">
                        <label
                            for="countries"
                            class="block mb-2 text-sm font-medium text-gray-900"
                            >Select Line</label
                        >
                        <select
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-cardinal-red focus:outline-none focus:ring-0 block w-full p-2.5"
                            v-model="selectedLine"
                            @change="updateFilters"
                        >
                            <option value="">All Line</option>
                            <option
                                v-for="line in lineOrder"
                                :value="line"
                                :key="line"
                            >
                                {{ line }}
                            </option>
                        </select>
                    </div> -->
                </div>
            </div>
        </div>
        <div
            class="flex max-w-screen-2xl mx-auto gap-y-10 flex-wrap justify-center"
        >
            <div
                class="p-2"
                v-for="(pcard, index) in facultyProfiles"
                :key="index"
            >
                <div
                    class="justify-center md:w-60 md:h-48 md:pt-6 bg-white rounded-lg shadow-lg w-36 h-36 pt-4"
                >
                    <div
                        v-if="!pcard.stanford_profile.length == 0"
                        v-for="(nested, index) in pcard.stanford_profile"
                        :key="index"
                    >
                        <a :href="pcard.cap_url" target="_blank">
                            <img
                                v-if="nested.photo_url_350 === null"
                                src="https://profiles.stanford.edu/proxy/api/cap/profiles/85797/resources/profilephoto/350x350.1509525173682.jpg"
                                alt="Placeholder"
                                class="relative mx-auto block md:w-24 h-auto w-16 rounded-lg bottom-11 md:bottom-14"
                                width="100"
                            />
                            <img
                                v-else
                                :src="nested.photo_url_350"
                                alt="Placeholder"
                                class="relative mx-auto block md:w-24 h-auto w-16 rounded-lg bottom-11 md:bottom-14"
                                width="100"
                            />
                        </a>
                    </div>

                    <div v-else>
                        <a :href="pcard.cap_url" target="_blank">
                            <img
                                src="https://profiles.stanford.edu/proxy/api/cap/profiles/85797/resources/profilephoto/350x350.1509525173682.jpg"
                                alt="Placeholder"
                                class="relative mx-auto block md:w-24 h-auto w-16 rounded-lg bottom-11 md:bottom-14"
                                width="100"
                            />
                        </a>
                    </div>

                    <div
                        class="text-center leading-tigh px-4 md:relative md:bottom-6"
                    >
                        <h1
                            class="md:text-xl text-sm text-center font-semibold"
                        >
                            <a
                                class="no-underline hover:underline text-center text-black"
                                :href="pcard.cap_url"
                                target="_blank"
                            >
                                {{ pcard.first_name }} {{ pcard.last_name }}
                            </a>
                        </h1>
                    </div>
                    <!-- <div
                        v-if="pcard.stanford_profile.length == 0"
                        class="text-center leading-tigh pt-3 px-4"
                    >
                        <h1 class="text-xl text-center font-semibold">
                            <a
                                class="no-underline hover:underline text-center text-black"
                                :href="pcard.cap_url"
                                target="_blank"
                            >
                                {{ pcard.first_name }} {{ pcard.last_name }}
                            </a>
                        </h1>
                    </div> -->
                    <!-- <div
                        v-else
                        v-for="(nested, index) in pcard.stanford_profile"
                        :key="index"
                        class="text-center leading-tigh pt-3 px-4"
                    >
                        <h1 class="text-xl text-center font-semibold">
                            <a
                                class="no-underline hover:underline text-center text-black"
                                :href="pcard.cap_url"
                                target="_blank"
                            >
                                {{ nested.display_name }}
                            </a>
                        </h1>
                    </div> -->

                    <div class="text-center md:relative md:bottom-6">
                        <!-- <h2 class="text-md text-center">
                            {{ pcard.line.name }},
                        </h2> -->
                        <h2
                            class="md:text-base text-xs text-center md:pb-4 pb-1"
                        >
                            {{ pcard.rank.level }}
                        </h2>
                    </div>
                </div>
            </div>
        </div>
        <!-- Pagination -->
        <div class="flex justify-center items-center py-8">
            <template v-for="(link, index) in paginateProfiles" :key="index">
                <!-- For mobile, only show Previous and Next -->
                <Link
                    v-if="
                        link.label.toLowerCase().includes('previous') ||
                        link.label.toLowerCase().includes('next')
                    "
                    :href="link.url || ''"
                    v-html="link.label"
                    class="px-3 py-2 leading-tight text-gray-500 bg-white border rounded-lg border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                    :class="{
                        'font-bold text-red-800': link.active,
                    }"
                />

                <!-- For desktop and larger, show all pagination links -->
                <Link
                    v-else-if="
                        !link.label.toLowerCase().includes('previous') &&
                        !link.label.toLowerCase().includes('next')
                    "
                    :href="link.url || ''"
                    v-html="link.label"
                    class="hidden md:block px-3 py-2 leading-tight text-gray-500 bg-white border rounded-lg border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                    :class="{
                        'bg-white': !link.url,
                        'font-bold text-red-800': link.active,
                    }"
                />
            </template>
        </div>
    </div>
</template>
