<script setup>
import { ref, computed } from "vue";
import FacultyCard from "@/Components/Cards/FacultyCard.vue";

const props = defineProps(["anna", "lauren", "meng", "linda"]);

const anna = ref(props.anna);
const lauren = ref(props.lauren);
const meng = ref(props.meng);
const linda = ref(props.linda);

const groupByFaculty = (data) => {
    let grouped = {};
    data.value.data.forEach((item) => {
        let name = item.faculty.first_name + " " + item.faculty.last_name;
        if (!grouped[name]) {
            grouped[name] = {
                profilePic: item.faculty.photo_url,
                name: name,
                publications: [],
            };
        }
        grouped[name].publications.push(item);
    });
    return Object.values(grouped);
};

const mengPublications = [
    {
        title: "Asthma heterogeneity amongst Asian American children: the California Health Interview Survey",
        link: '<a href="https://ws.isiknowledge.com/cps/openurl/service?url_ver=Z39.88-2004&rft_id=info:ut/000991651900228" target="_blank">web link</a>',
        pub_date: "2023-06-17",
    },
];

const getMengGroupWithManualPub = () => {
    const originalGroup = groupByFaculty(meng);

    if (originalGroup.length > 0) {
        originalGroup[0].publications =
            originalGroup[0].publications.concat(mengPublications);
    }

    return originalGroup;
};

const annaGroup = computed(() => groupByFaculty(anna));
const laurenGroup = computed(() => groupByFaculty(lauren));
const mengGroup = computed(() => getMengGroupWithManualPub(meng));
const lindaGroup = computed(() => groupByFaculty(linda));

const annaTitle = "Clinical Associate Professor of Medicine"; // Change these according to your data
const laurenTitle = "Clinical Assistant Professor of Medicine";
const mengTitle = "Clinical Assistant Professor of Medicine";
const lindaTitle = "Clinical Assistant Professor of Medicine";
</script>

<template>
    <div>
        <div class="flex flex-wrap justify-center gap-4 md:px-6 md:mt-6">
            <FacultyCard
                :groupedData="annaGroup"
                name="Anna Chen Arroyo, MD MPH"
                :title="annaTitle"
            />
            <FacultyCard
                :groupedData="laurenGroup"
                name="Lauren E. Eggert, MD"
                :title="laurenTitle"
            />
        </div>
        <div class="flex flex-wrap justify-center gap-4 md:px-6">
            <FacultyCard
                :groupedData="mengGroup"
                name="Meng Chen, MD"
                :title="mengTitle"
            />
            <FacultyCard
                :groupedData="lindaGroup"
                name="Linda Zhu, MD"
                :title="lindaTitle"
            />
        </div>
    </div>
</template>
