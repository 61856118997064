<script setup>
import { ref, watch, computed } from "vue";
import { Link, router } from "@inertiajs/vue3";

const props = defineProps({
    labs: Object,
    filters: Object,
    division: Array,
    focus: Array,
});

const labsProp = ref(props.labs);
const divProp = ref(props.division);
const focusProp = ref(props.focus);

const labsData = labsProp.value.data;
const paginateProfiles = labsProp.value.links;
const divPropData = divProp.value;
const focusList = focusProp.value;

const search = ref(props.filters.search || "");
const selectedDivision = ref(props.filters.division || "");
const selectedFocus = ref(
    props.filters.focus ? props.filters.focus.split(",") : []
);

const isFocusExpanded = ref(false);

const applyFilters = () => {
    const queryParams = {};

    if (search.value) {
        queryParams.search = search.value;
    }
    if (selectedDivision.value !== "") {
        queryParams.division = selectedDivision.value;
    }
    if (selectedFocus.value.length > 0) {
        queryParams.focus = selectedFocus.value.join(",");
    }

    router.get(route("lab", queryParams));
};

const filterByFocus = (focusId) => {
    selectedFocus.value = [focusId];
    applyFilters();
};

const clearAllFilters = () => {
    search.value = ""; // Clear the search query
    selectedDivision.value = ""; // Clear the selected division
    selectedFocus.value = []; // Clear the selected focus areas

    applyFilters(); // Reload the labs without any filters
};
</script>

<template>
    <div class="bg-gray-100">
        <div class="pt-12 pb-5">
            <div class="max-w-screen-xl mx-auto sm:px-6 lg:px-8">
                <div
                    class="grid md:grid-cols-2 gap-4 bg-white overflow-hidden shadow-sm sm:rounded-lg sm:grid-cols-2 sm:grid-row-2"
                >
                    <input
                        v-model="search"
                        type="text"
                        placeholder="Search by Lab Name or PI Name"
                        class="p-6 bg-white border-0 focus:border-2 focus:border-cardinal-red focus:rounded-l-lg text-lg focus:outline-none focus:ring-0"
                        @keyup.enter="applyFilters(search)"
                    />

                    <div class="p-2">
                        <label
                            for="countries"
                            class="block mb-2 text-sm font-medium text-gray-900"
                            >Select Division</label
                        >
                        <select
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-cardinal-red focus:outline-none focus:ring-0 block w-full p-2.5 cursor-pointer"
                            v-model="selectedDivision"
                            @change="applyFilters"
                        >
                            <option value="">All Divisions</option>
                            <option
                                v-for="division in divPropData"
                                :value="division.id"
                                :key="division.id"
                            >
                                {{ division.display_name }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="px-5 pt-5">
                    <div
                        @click="isFocusExpanded = !isFocusExpanded"
                        class="cursor-pointer flex items-center"
                    >
                        <label
                            for="focusAreas"
                            class="mb-2 text-sm md:text-md font-medium text-gray-900 hover:text-cardinal-red hover:underline cursor-pointer"
                            >Select Focus Area</label
                        >
                        <svg
                            v-if="!isFocusExpanded"
                            class="h-5 w-5 ml-2 text-cardinal-red"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                clip-rule="evenodd"
                            />
                        </svg>
                        <svg
                            v-if="isFocusExpanded"
                            class="h-5 w-5 ml-2 text-cardinal-red"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    </div>

                    <div v-if="isFocusExpanded">
                        <div class="grid grid-cols-2 md:grid-cols-5 pt-2 pl-4">
                            <div v-for="focus in focusList" :key="focus">
                                <input
                                    type="checkbox"
                                    :id="focus.id"
                                    :value="focus.id"
                                    class="mr-2 text-cardinal-red outline-cardinal-red ring-cardinal-red cursor-pointer focus:border-cardinal-red focus:ring-cardinal-red"
                                    checked
                                    v-model="selectedFocus"
                                />
                                <label
                                    class="text-xs md:text-sm ring-cardinal-red"
                                    :for="focus.id"
                                    >{{ focus.name }}</label
                                >
                            </div>
                            <!-- <div>
                                <input
                                    class="mr-2 accent-cardinal-red focus:accent-cardinal-red"
                                    type="checkbox"
                                    value=""
                                    @change="clearAllFilters"
                                />
                                <label class="text-xs md:text-sm"
                                    >Select All</label
                                >
                            </div> -->
                        </div>
                        <div class="flex gap-4 justify-end pr-4">
                            <button
                                @click="clearAllFilters"
                                class="bg-gray-400 hover:bg-cardinal-red text-white text-sm font-semi py-2 px-4 rounded"
                            >
                                Clear Filters
                            </button>
                            <button
                                @click="applyFilters"
                                class="bg-cardinal-red hover:bg-cardinal-red-light text-white text-sm font-semi py-2 px-4 rounded"
                            >
                                Apply Filters
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="grid grid-cols-1 md:flex max-w-screen-2xl mx-auto flex-wrap justify-center"
        >
            <div class="p-2" v-for="(labCard, index) in labsData" :key="index">
                <div
                    class="overflow-hidden justify-center md:w-80 md:h-80 bg-white rounded-lg shadow-lg sm:w-36 sm:h-56 xs:w-36 xs:h-56"
                >
                    <div>
                        <a :href="labCard.url" target="_blank">
                            <img
                                v-if="labCard.img !== 'null'"
                                :src="labCard.img"
                                :href="labCard.url"
                                alt="test"
                                class="mx-auto block object-cover h-36 w-full overflow-hidden"
                            />
                            <img
                                v-else
                                src="https://placehold.co/550x250"
                                :href="labCard.url"
                                alt="img"
                                class="mx-auto block h-auto w-full overflow-hidden"
                            />
                        </a>
                    </div>

                    <div class="text-center leading-tigh pt-3 px-4">
                        <h1 class="text-xl text-center font-semibold">
                            <a
                                class="no-underline hover:underline text-center text-black hover:text-cardinal-red"
                                :href="labCard.url"
                                target="_blank"
                            >
                                {{ labCard.name }}
                            </a>
                        </h1>
                    </div>

                    <div class="text-center">
                        <h2 class="text-md text-center pb-4">
                            {{ labCard.pi_name }}
                        </h2>
                    </div>

                    <div class="text-center">
                        <h2
                            class="text-sm text-center h-10 overflow-hidden px-6"
                        >
                            {{ labCard.address }}
                        </h2>
                    </div>

                    <div
                        class="flex flex-wrap gap-1 px-6 py-2 justify-center items-center"
                    >
                        <h2 class="text-xs font-semibold">Focus:</h2>
                        <button
                            v-for="(focus, index) in labCard.focus_areas"
                            :key="index"
                            @click="filterByFocus(focus.id)"
                            class="text-xs text-blue-700 underline focus:outline-none hover:text-cardinal-red"
                        >
                            {{ focus.name }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Pagination -->
        <div class="flex justify-center items-center py-8">
            <template v-for="(link, index) in paginateProfiles" :key="index">
                <!-- For mobile, only show Previous and Next -->
                <Link
                    v-if="
                        link.label.toLowerCase().includes('previous') ||
                        link.label.toLowerCase().includes('next')
                    "
                    :href="link.url || ''"
                    v-html="link.label"
                    class="px-3 py-2 leading-tight text-gray-500 bg-white border rounded-lg border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                    :class="{
                        'font-bold text-red-800': link.active,
                    }"
                />

                <!-- For desktop and larger, show all pagination links -->
                <Link
                    v-else-if="
                        !link.label.toLowerCase().includes('previous') &&
                        !link.label.toLowerCase().includes('next')
                    "
                    :href="link.url || ''"
                    v-html="link.label"
                    class="hidden md:block px-3 py-2 leading-tight text-gray-500 bg-white border rounded-lg border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                    :class="{
                        'bg-white': !link.url,
                        'font-bold text-red-800': link.active,
                    }"
                />
            </template>
        </div>
    </div>
</template>
