<template>
    <div ref="mapContainer" class="map"></div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";

// Define the 'address' prop
const props = defineProps({
    address: String,
});
const mapContainer = ref(null);

// Function to load the Google Maps API
const loadGoogleMapsApi = () => {
    return new Promise((resolve, reject) => {
        if (window.google) {
            resolve();
            return;
        }

        window.initMap = resolve;

        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDeqXQs9xeWl3tpERrxGXlLoGFy8TS0LrU&callback=initMap`;
        script.async = true;
        script.defer = true; // Add this line
        document.head.appendChild(script);
    });
};

// Function to initialize the map
const initMap = async () => {
    await loadGoogleMapsApi();

    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: props.address }, (results, status) => {
        if (status === "OK") {
            const location = results[0].geometry.location;

            const map = new google.maps.Map(mapContainer.value, {
                center: location,
                zoom: 15,
            });

            const marker = new google.maps.Marker({
                map: map,
                position: location,
            });

            // Function to handle map and marker click
            const handleMapClick = () => {
                const encodedAddress = encodeURIComponent(props.address);
                const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
                window.open(googleMapsUrl, "_blank");
            };

            // Add click event listener to the marker
            marker.addListener("click", handleMapClick);

            // Add click event listener to the entire map
            map.addListener("click", handleMapClick);
        } else {
            console.error("Geocode was not successful: " + status);
        }
    });
};

onMounted(() => {
    initMap();
});

// Watch for changes in the address prop
watch(
    () => props.address,
    (newAddress) => {
        if (newAddress) {
            initMap();
        }
    }
);
</script>

<style>
.map {
    height: 300px;
    width: 100%;
}
</style>

<style>
.map {
    height: 300px;
    width: 100%;
}
</style>
