<script setup>
import { ref, watch, computed } from "vue";
import { Link, router } from "@inertiajs/vue3";
import debounce from "lodash.debounce";
import ModelCard from "@/Components/Cards/ModelCard.vue";
import GoogleMap from "@/Components/Cards/GoogleMapComp.vue";

const props = defineProps({
    profile: Object,
    filters: Object,
    ranks: Array,
    lines: Array,
    departments: Array,
    locations: Array,
});

const facultyProfiles = computed(() => props.profile.data);
const paginateProfiles = computed(() => props.profile.links);
const departments = computed(() => _.orderBy(props.departments, "name", "asc"));
const ranks = computed(() => props.ranks);
const lines = computed(() => props.lines);
const locations = computed(() => props.locations);

// console.log(locations);

const search = ref(props.filters.search || "");
const selectedRank = ref(props.filters.rank || "");
const selectedLine = ref(props.filters.line || "");
const selectedDepartment = ref(props.filters.department || "");
const selectedLocation = ref(props.filters.location || "");

watch(
    [search, selectedRank, selectedLine, selectedDepartment, selectedLocation],
    debounce(
        () => {
            router.get(
                route("srpprofile", {
                    search: search.value,
                    rank: selectedRank.value,
                    line: selectedLine.value,
                    department: selectedDepartment.value,
                    location: selectedLocation.value,
                }),
                {
                    preserveState: true,
                    preserveScroll: true,
                }
            );
        },
        600,
        { leading: false }
    )
);

const isModalVisible = ref(false);
const selectedCard = ref(null);

const openModal = (card) => {
    selectedCard.value = card;
    setTimeout(() => {
        isModalVisible.value = true;
    }, 300); // Delay for card animation
};

const closeModal = () => {
    isModalVisible.value = false;
    selectedCard.value = null;
};
</script>

<template>
    <div class="bg-transparent">
        <div class="pt-12">
            <div class="max-w-screen-2xl mx-auto sm:px-6 lg:px-8">
                <div
                    class="grid md:grid-cols-5 gap-4 bg-white overflow-hidden shadow-lg sm:rounded-lg sm:grid-cols-2 sm:grid-row-2"
                >
                    <input
                        v-model="search"
                        type="text"
                        placeholder="Search by Faculty Name"
                        class="p-6 bg-white border-0 focus:border-2 focus:border-cardinal-red focus:rounded-l-lg text-base focus:outline-none focus:ring-0"
                    />

                    <div class="p-2">
                        <label
                            for="departmentSelect"
                            class="block mb-2 text-sm font-medium text-gray-900"
                            >Select Department</label
                        >
                        <select
                            id="departmentSelect"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-cardinal-red focus:outline-none focus:ring-0 block w-full p-2.5"
                            v-model="selectedDepartment"
                            @change="updateFilters"
                        >
                            <option value="">All Departments</option>
                            <option
                                v-for="dept in departments"
                                :value="dept.id"
                                :key="dept.id"
                            >
                                {{ dept.display_name }}
                            </option>
                        </select>
                    </div>

                    <div class="p-2">
                        <label
                            for="rankSelect"
                            class="block mb-2 text-sm font-medium text-gray-900"
                            >Select Location</label
                        >
                        <select
                            id="rankSelect"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-cardinal-red focus:outline-none focus:ring-0 block w-full p-2.5"
                            v-model="selectedLocation"
                            @change="updateFilters"
                        >
                            <option value="">All Locations</option>
                            <option
                                v-for="loc in locations"
                                :value="loc"
                                :key="loc"
                            >
                                {{ loc }}
                            </option>
                        </select>
                    </div>

                    <div class="p-2">
                        <label
                            for="rankSelect"
                            class="block mb-2 text-sm font-medium text-gray-900"
                            >Select Rank</label
                        >
                        <select
                            id="rankSelect"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-cardinal-red focus:outline-none focus:ring-0 block w-full p-2.5"
                            v-model="selectedRank"
                            @change="updateFilters"
                        >
                            <option value="">All Rank Levels</option>
                            <option
                                v-for="rank in ranks"
                                :value="rank"
                                :key="rank"
                            >
                                {{ rank }}
                            </option>
                        </select>
                    </div>

                    <div class="p-2">
                        <label
                            for="lineSelect"
                            class="block mb-2 text-sm font-medium text-gray-900"
                            >Select Line</label
                        >
                        <select
                            id="lineSelect"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-cardinal-red focus:outline-none focus:ring-0 block w-full p-2.5"
                            v-model="selectedLine"
                            @change="updateFilters"
                        >
                            <option value="">All Lines</option>
                            <option
                                v-for="line in lines"
                                :value="line"
                                :key="line"
                            >
                                {{ line }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="text-center font-medium text-lg text-white py-6">
            <h1>*CLICK ON FACULTY CARD FOR MORE INFORMATION</h1>
        </div>

        <div class="flex max-w-screen-2xl mx-auto flex-wrap justify-center">
            <div
                class="p-2 cursor-pointer"
                v-for="(pcard, index) in facultyProfiles"
                :key="index"
                @click="openModal(pcard)"
                :class="{
                    'card-expand': selectedCard === pcard && isModalVisible,
                }"
            >
                <div
                    class="overflow-hidden justify-center md:w-40 bg-white hover:bg-cardinal-red hover:text-white rounded-lg sm:w-36 sm:h-56 xs:w-36 xs:h-56 shadow-lg"
                >
                    <img
                        v-if="pcard.photo_url === null"
                        src="https://www.pngkey.com/png/detail/121-1219231_user-default-profile.png"
                        alt="Placeholder"
                        class="mx-auto block object-cover h-36 w-full overflow-hidden"
                        width="100"
                    />
                    <img
                        v-else
                        :src="pcard.photo_url"
                        alt="Placeholder"
                        class="mx-auto block object-cover h-36 w-full overflow-hidden"
                        width="100"
                    />

                    <div class="text-center leading-tigh pt-3 px-4">
                        <h1 class="text-xl text-center font-semibold">
                            {{ pcard.first_name }} {{ pcard.last_name }}
                        </h1>
                    </div>
                </div>
            </div>

            <!-- Modal -->
            <ModelCard :isVisible="isModalVisible" @close="closeModal">
                <div
                    v-if="selectedCard"
                    class="modal-container text-center text-lg"
                >
                    <div class="pb-2 pt-2">
                        <img
                            v-if="selectedCard.photo_url"
                            :src="selectedCard.photo_url"
                            alt="Faculty Photo"
                            class="mx-auto block h-auto rounded-full"
                            width="150"
                        />
                        <img
                            v-else
                            src="https://www.pngkey.com/png/full/73-730477_first-name-profile-image-placeholder-png.png"
                            alt="Placeholder"
                            class="mx-auto block h-auto rounded-full"
                            width="150"
                        />
                    </div>
                    <h1 class="text-2xl text-center font-semibold pb-2">
                        {{ selectedCard.first_name }}
                        {{ selectedCard.last_name }}
                    </h1>
                    <p>{{ selectedCard.rank }}, {{ selectedCard.line }}</p>
                    <p class="pb-2">
                        Department: {{ selectedCard.department.name }}
                    </p>
                    <a
                        class="no-underline hover:underline text-center font-extrabold cursor-pointer text-cardinal-red hover:text-cardinal-red"
                        :href="selectedCard.cap_url"
                        target="_blank"
                        >CAP Profile</a
                    >
                    <div class="pt-2 pb-4 px-4">
                        <h1 class="pb-2">
                            Location: {{ selectedCard.address }}
                        </h1>
                        <GoogleMap :address="selectedCard.address" />
                    </div>
                </div>
            </ModelCard>
        </div>
        <!-- Pagination -->
        <div class="flex justify-center items-center py-8">
            <template v-for="(link, index) in paginateProfiles" :key="index">
                <!-- For mobile, only show Previous and Next -->
                <Link
                    v-if="
                        link.label.toLowerCase().includes('previous') ||
                        link.label.toLowerCase().includes('next')
                    "
                    :href="link.url || ''"
                    v-html="link.label"
                    class="px-3 py-2 leading-tight text-gray-500 shadow-lg bg-white border rounded-lg border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                    :class="{
                        'font-bold text-red-800': link.active,
                    }"
                />

                <!-- For desktop and larger, show all pagination links -->
                <Link
                    v-else-if="
                        !link.label.toLowerCase().includes('previous') &&
                        !link.label.toLowerCase().includes('next')
                    "
                    :href="link.url || ''"
                    v-html="link.label"
                    class="hidden md:block px-3 py-2 leading-tight shadow-lg text-gray-500 bg-white border rounded-lg border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                    :class="{
                        'bg-white': !link.url,
                        'font-bold text-red-800': link.active,
                    }"
                />
            </template>
        </div>
    </div>
</template>

<style>
.card-expand {
    transition: transform 0.3s;
    transform: scale(1.2);
    z-index: 10;
}

.modal-container {
    width: 600px;
    max-width: 1200px;
    height: auto;
    padding: 10px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    margin: 10px auto;
}

.map-container {
    height: 300px;
}
</style>
