<script setup>
import { ref, computed } from "vue";
import { Link, router } from "@inertiajs/vue3";
import BarChart from "@/Components/Charts/BarChart.vue";
import DefaultLayout from "@/Layouts/DefaultLayout.vue";
import axios from "axios";

const props = defineProps(["counts", "pubs", "selectedYear"]);

const countsTotal = ref(props.counts);
const publications = ref(props.pubs);
const selectedYear = ref(props.selectedYear);

const data = publications.value.data;
const countData = countsTotal.value;
const pubPaginate = publications.value.links;

const paginationLinks = ref([]);
const pubFilter = ref([]);
const urlBlank = "";

const votedPublications = ref(new Set());

function getYear(year) {
    let url = "";

    if (year !== null) {
        url = `/?year=${year}`;
    } else {
        url = `/?year=${url}`;
    }
    router.get(`/?year=${year}`, {
        onSuccess: (dataSet) => {
            pubFilter.value = dataSet.publications;
            selectedYear.value = year;
            paginationLinks.value = dataSet.paginationLinks;
        },
        onError: (error) => {
            console.error(error);
        },
        // preserveState: true,
    });
}

async function vote(publication) {
    try {
        let response = await axios.post(`/${publication.id}/vote`);
        if (response.data && response.data.votes) {
            publication.votes = response.data.votes;
        }

        votedPublications.value.add(publication.id);
    } catch (error) {
        console.error("Error voting:", error);
    }
}

const dataYears = _.forEach(countData, function (value, key) {
    return value.pub_year;
});

const pubByYear = computed(() => {
    const pubYearAll = dataYears.map((item) => String(item.pub_year));
    const pubCountAll = dataYears.map((item) => item.total);

    if (selectedYear.value === null) {
        return {
            labels: pubYearAll,
            datasets: [
                {
                    label: "Publications by Year",
                    data: pubCountAll,
                    backgroundColor: "rgb(100, 149, 237)",
                    borderRadius: "5",
                },
            ],
        };
    } else {
        const barThickness = 100;

        const selectedData = dataYears.find(
            (item) => String(item.pub_year) === String(selectedYear.value)
        );

        const selectedCount = selectedData?.total || 0;

        return {
            labels: [selectedYear.value],
            datasets: [
                {
                    label: "Publications by Year",
                    data: [selectedCount],
                    backgroundColor: "rgb(100, 149, 237)",
                    borderRadius: "5",
                    barThickness: barThickness,
                },
            ],
        };
    }
});
</script>

<template>
    <DefaultLayout class="bg-gray-100">
        <div class="w-full max-w-screen-lg mx-auto p-6 bg-gray-100">
            <div class="p-12 bg-white border-b border-gray-400 shadow-md">
                <!-- <h1 class="items-center text-gray-900 sm:text-3xl">
                    Preeminence in Research Productivity
                </h1> -->

                <h1 class="items-center text-lg md:text-3xl text-gray-600">
                    Publications by Department
                </h1>
                <br />
                <BarChart :data="pubByYear" />
                <br />

                <!-- <h1 class="items-center text-1xl text-gray-600">
                    Department Publications By Division
                </h1>
                <br /> -->
                <!-- <BarChart /> -->
                <br />
                <h1 class="items-center text-gray-600 text-sm md:text-xl">
                    Filter By Year
                </h1>

                <div class="p-6 bg-white">
                    <div
                        class="grid gap-2 md:gap-4 grid-cols-4 md:grid-cols-6 items-center"
                    >
                        <div v-for="(btn, index) in countData" :key="index">
                            <button
                                type="button"
                                class="bg-gray-300 hover:bg-cardinal-red text-black-700 hover:text-white text-xs w-12 h-4 md:w-24 md:h-10 rounded-full md:text-xl"
                                @click="getYear(btn.pub_year)"
                                v-bind:class="{
                                    ' bg-red-800 text-white':
                                        selectedYear == btn.pub_year,
                                }"
                            >
                                {{ btn.pub_year }}
                            </button>
                        </div>
                        <div>
                            <button
                                type="button"
                                class="bg-gray-300 hover:bg-cardinal-red text-black-700 hover:text-white text-xs w-12 h-4 md:w-24 md:h-10 rounded-full md:text-xl"
                                @click="getYear(urlBlank)"
                                v-bind:class="{
                                    ' bg-red-800 text-white':
                                        selectedYear === null,
                                }"
                            >
                                All Years
                            </button>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <div>
                    <h1 class="items-center text-gray-600 text-sm md:text-xl">
                        Publications
                    </h1>
                    <table
                        class="min-w-full mt-4 bg-white divide-y divide-gray-200"
                    >
                        <thead class="bg-gray-50">
                            <tr
                                class="px-6 py-12 p-6 text-xs md:text-base font-medium tracking-wider text-left text-gray-500 uppercase"
                            >
                                <th>Title</th>
                                <th>pmid</th>
                                <th>year</th>
                                <th class="pl-4">date</th>
                                <!-- <th>Votes</th> -->
                            </tr>
                        </thead>

                        <tbody class="bg-white divide-y divide-gray-200">
                            <tr v-for="(x, index) in data" :key="index">
                                <td
                                    class="text-xs md:max-w-sm md:text-lg py-2 pr-6"
                                >
                                    <p v-html="x.title" />
                                </td>
                                <td class="w-24">
                                    <a
                                        class="text-blue-600 underline hover:blue text-xs md:text-lg"
                                        :href="
                                            'https://pubmed.ncbi.nlm.nih.gov/' +
                                            x.pmid
                                        "
                                        target="_blank"
                                        >{{ x.pmid }}</a
                                    >
                                </td>

                                <td class="text-xs md:text-lg">
                                    {{ x.pub_year }}
                                </td>
                                <td class="w-28 pl-4 text-xs md:text-lg">
                                    {{ x.pub_date }}
                                </td>
                                <!-- <td class="text-xs md:text-lg">
                                    <button
                                        @click="vote(x)"
                                        :disabled="votedPublications.has(x.id)"
                                        class="px-2 py-1 bg-gray-100 rounded-lg hover:bg-gray-300"
                                    >
                                        👍 {{ x.votes || 0 }}
                                    </button>
                                </td> -->
                            </tr>
                        </tbody>
                    </table>

                    <!-- Pagination -->
                    <div class="flex justify-center items-center py-8">
                        <template
                            v-for="(link, index) in pubPaginate"
                            :key="index"
                        >
                            <!-- For mobile, only show Previous and Next -->
                            <Link
                                v-if="
                                    link.label
                                        .toLowerCase()
                                        .includes('previous') ||
                                    link.label.toLowerCase().includes('next')
                                "
                                :href="link.url || ''"
                                v-html="link.label"
                                class="text-xs md:text-lg px-3 py-2 leading-tight text-gray-500 bg-white border rounded-lg border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                                :class="{
                                    'font-bold text-red-800': link.active,
                                }"
                            />

                            <!-- For desktop and larger, show all pagination links -->
                            <Link
                                v-else-if="
                                    !link.label
                                        .toLowerCase()
                                        .includes('previous') &&
                                    !link.label.toLowerCase().includes('next')
                                "
                                :href="link.url || ''"
                                v-html="link.label"
                                class="text-xs md:text-lg hidden md:block px-3 py-2 leading-tight text-gray-500 bg-white border rounded-lg border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                                :class="{
                                    'bg-white': !link.url,
                                    'font-bold text-red-800': link.active,
                                }"
                            />
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </DefaultLayout>
</template>
