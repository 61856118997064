<script setup>
import { ref, computed, watch, onMounted, onUnmounted } from "vue";
import { Link, router } from "@inertiajs/vue3";
import BarChart from "@/Components/Charts/BarChart.vue";

const props = defineProps([
    "pubs",
    "facultyCount",
    "facultyInput",
    "selectedYear",
    "counts",
    "faculty",
]);

const pubs = ref(props.pubs);
const facultyCount = ref(props.facultyCount);
const facultyInput = ref(props.facultyInput);
const selectedYear = ref(props.selectedYear);
const countsTotal = ref(props.counts);
const facultyList = ref(props.faculty);

const pubData = pubs.value.data;
const pubValues = pubs.value;
const pubPaginate = pubValues.links;
const countData = countsTotal.value;
const facCount = facultyCount.value;
const facList = facultyList.value;

const pubFilter = ref([]);
const paginationLinks = ref([]);

const suggestions = ref([]);
const searchInputRef = ref(null);
const suggestionListRef = ref(null);

const facultyNames = _.uniq(
    facList.map((item) => {
        const fullName = [item.first_name, item.last_name]
            .filter(Boolean) // Remove empty values (null or undefined)
            .map((namePart) => namePart.toLowerCase())
            .join(" ");
        return fullName;
    })
);

const selectSuggestion = (suggestion) => {
    facultyInput.value = suggestion;
    suggestions.value = []; // Clear suggestions after selecting one
    searchFaculty(facultyInput.value); // Perform search with autofilled suggestion
};

function searchFaculty(name, year) {
    let url = "";

    if (name) {
        url = `?faculty=${name}`;
    } else {
        url = `?faculty=`;
    }

    if (selectedYear.value) {
        url += `&year=${selectedYear.value}`;
    }

    router.get(url, {
        onSuccess: (dataSet) => {
            facultyInput.value = name;
            pubFilter.value = dataSet.publications;
            // selectedYear.value = year;
            paginationLinks.value = dataSet.paginationLinks;
        },
        onError: (error) => {
            console.error(error);
        },
    });
}

watch(facultyInput, (newInput) => {
    const matchedNames = facultyNames.filter((name) =>
        name.toLowerCase().includes(newInput.toLowerCase())
    );
    suggestions.value = matchedNames;
});

watch(selectedYear, (newYear) => {
    searchFaculty(facultyInput.value);
});

const dataYears = _.forEach(countData, function (value, key) {
    return value.pub_year;
});

const calculateBarThickness = (dataSize) => {
    if (dataSize <= 1) {
        return 150; // Larger thickness for fewer data points.
    } else {
        // return Math.max(100 - dataSize * 10, 10);
    }
};

const facultyTotal = computed(() => {
    const pubYearAll = dataYears
        .map((item) => String(item.pub_year))
        .sort((a, b) => a.localeCompare(b));
    const pubCountAll = dataYears.map((item) => item.total);

    const pubYear = facCount.map((item) => item.pub_year);
    const pubCount = facCount.map((item) => item.total);

    const facultyFirst = _.uniq(
        facCount.map((item) => item.first_name.toLowerCase())
    );
    const facultyLast = _.uniq(
        facCount.map((item) => item.last_name.toLowerCase())
    );

    if (facultyInput.value === null) {
        return {
            labels: pubYearAll,
            datasets: [
                {
                    label: "Publications by Years",
                    data: pubCountAll,
                    backgroundColor: "rgb(100, 149, 237)",
                    borderRadius: "5",
                },
            ],
        };
    } else if (
        facultyInput.value.toLowerCase() == facultyFirst &&
        selectedYear.value === null
    ) {
        const sortedData = pubYear
            .map((year, index) => ({
                year: year,
                count: pubCount[index],
            }))
            .sort((a, b) => a.year - b.year);

        // Separate back into sorted pubYear and pubCount
        const sortedPubYear = sortedData.map((item) => item.year);
        const sortedPubCount = sortedData.map((item) => item.count);

        return {
            labels: sortedPubYear,
            datasets: [
                {
                    label:
                        "Publications by " + facultyFirst + " " + facultyLast,
                    data: sortedPubCount,
                    backgroundColor: "rgb(100, 149, 237)",
                    borderRadius: "5",
                },
            ],
        };
    } else if (
        facultyInput.value.toLowerCase() == facultyFirst + " " + facultyLast &&
        selectedYear.value === null
    ) {
        const sortedData = pubYear
            .map((year, index) => ({
                year: year,
                count: pubCount[index],
            }))
            .sort((a, b) => a.year - b.year);

        // Separate back into sorted pubYear and pubCount
        const sortedPubYear = sortedData.map((item) => item.year);
        const sortedPubCount = sortedData.map((item) => item.count);

        return {
            labels: sortedPubYear,
            datasets: [
                {
                    label:
                        "Publications by " + facultyFirst + " " + facultyLast,
                    data: sortedPubCount,
                    backgroundColor: "rgb(100, 149, 237)",
                    borderRadius: "5",
                },
            ],
        };
    } else if (
        facultyInput.value.toLowerCase() == facultyLast &&
        selectedYear.value === null
    ) {
        const sortedData = pubYear
            .map((year, index) => ({
                year: year,
                count: pubCount[index],
            }))
            .sort((a, b) => a.year - b.year);

        // Separate back into sorted pubYear and pubCount
        const sortedPubYear = sortedData.map((item) => item.year);
        const sortedPubCount = sortedData.map((item) => item.count);

        return {
            labels: sortedPubYear,
            datasets: [
                {
                    label:
                        "Publications by " + facultyFirst + " " + facultyLast,
                    data: sortedPubCount,
                    backgroundColor: "rgb(100, 149, 237)",
                    borderRadius: "5",
                },
            ],
        };
    } else if (facultyInput.value !== null && selectedYear.value !== null) {
        const dataSize = facCount.length;
        const barThickness = calculateBarThickness(dataSize);
        return {
            labels: pubYear,
            datasets: [
                {
                    label:
                        "Publications by " + facultyFirst + " " + facultyLast,
                    data: pubCount,
                    backgroundColor: "rgb(100, 149, 237)",
                    borderRadius: "5",
                    barThickness: barThickness,
                },
            ],
        };
    } else {
        return {
            labels: "",
            datasets: [
                {
                    label: "Publications by Year",
                    data: "",
                    backgroundColor: "rgb(100, 149, 237)",
                    borderRadius: "5",
                },
            ],
        };
    }
});

onMounted(() => {
    // Add click event listener to the document
    document.addEventListener("click", handleDocumentClick);
});

onUnmounted(() => {
    // Remove the event listener when the component is unmounted
    document.removeEventListener("click", handleDocumentClick);
});

const handleDocumentClick = (event) => {
    const isClickedOutside =
        searchInputRef.value &&
        !searchInputRef.value.contains(event.target) &&
        suggestionListRef.value &&
        !suggestionListRef.value.contains(event.target);

    if (isClickedOutside) {
        suggestions.value = []; // Clear suggestions
    }
};
</script>

<template>
    <div class="w-full max-w-screen-xl mx-auto p-6 bg-white">
        <div class="w-full">
            <BarChart
                v-if="facultyTotal && facultyTotal.datasets.length > 0"
                :data="facultyTotal"
            />
        </div>

        <div class="grid grid-cols-1 md:grid-cols-3 gap-6 pt-6">
            <div class="col-span-1 md:col-span-1">
                <h1 class="items-center text-gray-600 text-md md:text-xl pb-4">
                    Search by Faculty
                </h1>
                <input
                    type="text"
                    v-model="facultyInput"
                    ref="searchInput"
                    placeholder="Enter First, Last, or Full Name of Faculty"
                    class="w-full h-10 px-3 text-sm md:text-base placeholder-gray-400 border rounded-lg focus:border-cardinal-red focus:outline-none focus:ring-0"
                    @keyup.enter="searchFaculty(facultyInput)"
                />
                <button
                    class="w-full h-10 px-3 mt-4 text-sm md:text-base text-white bg-cardinal-red rounded-lg hover:caret-cardinal-red"
                    @click="searchFaculty(facultyInput)"
                >
                    Search
                </button>
                <div
                    v-if="suggestions.length"
                    ref="suggestionList"
                    class="z-10 absolute mt-1 bg-white border border-gray-300 shadow-md rounded-lg max-h-40 overflow-y-auto"
                >
                    <ul class="space-y-1">
                        <li
                            v-for="suggestion in suggestions.slice(0, 10)"
                            :key="suggestion"
                            class="cursor-pointer px-3 py-1 hover:bg-gray-100"
                            @click="selectSuggestion(suggestion)"
                        >
                            {{ suggestion }}
                        </li>
                    </ul>
                </div>
                <div class="bg-white pt-4">
                    <h1
                        class="items-center text-gray-600 text-md md:text-xl pb-4"
                    >
                        Filter By Year
                    </h1>
                    <div class="relative">
                        <select
                            v-model="selectedYear"
                            class="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-cardinal-red focus:text-gray-900"
                        >
                            <option value="">All Years</option>
                            <option
                                v-for="(btn, index) in countData"
                                :key="index"
                                :value="btn.pub_year"
                            >
                                {{ btn.pub_year }}
                            </option>
                        </select>
                        <!-- <div
                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                        >
                            <svg
                                class="fill-current h-4 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                            >
                                <path
                                    d="M9.293 11.293a1 1 0 011.414 0l4 4a1 1 0 11-1.414 1.414l-3.293-3.293a1 1 0 00-1.414 0L4.293 16.707a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0z"
                                />
                            </svg>
                        </div> -->
                    </div>
                </div>
            </div>

            <div
                class="max-h-[500px] overflow-y-scroll col-span-1 md:col-span-2 scrollable-div"
            >
                <table class="bg-white divide-y divide-gray-200 w-full">
                    <thead class="bg-gray-50">
                        <tr
                            class="text-xs md:text-base font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                            <!-- <th>Author</th> -->
                            <th>Title</th>
                            <th>pmid</th>
                            <th>year</th>
                        </tr>
                    </thead>

                    <tbody class="bg-white divide-y divide-gray-200">
                        <tr v-for="(x, index) in pubData" :key="index">
                            <!-- <td>{{ x.first_name + " " + x.last_name }}</td> -->
                            <td
                                class="text-xs md:max-w-sm md:text-lg py-2 pr-6"
                            >
                                <p v-html="x.title" />
                            </td>
                            <td>
                                <a
                                    class="text-blue-600 underline hover:blue text-center text-xs md:text-lg"
                                    :href="
                                        'https://pubmed.ncbi.nlm.nih.gov/' +
                                        x.pmid
                                    "
                                    target="_blank"
                                    >{{ x.pmid }}</a
                                >
                            </td>
                            <td class="text-xs md:text-lg">
                                {{ x.pub_year }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- Pagination -->
        <div class="flex justify-center items-center py-8">
            <template v-for="(link, index) in pubPaginate" :key="index">
                <!-- For mobile, only show Previous and Next -->
                <Link
                    v-if="
                        link.label.toLowerCase().includes('previous') ||
                        link.label.toLowerCase().includes('next')
                    "
                    :href="link.url || ''"
                    v-html="link.label"
                    class="px-3 py-2 leading-tight text-xs md:text-lg text-gray-500 bg-white border rounded-lg border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                    :class="{
                        'font-bold text-red-800': link.active,
                    }"
                />

                <!-- For desktop and larger, show all pagination links -->
                <Link
                    v-else-if="
                        !link.label.toLowerCase().includes('previous') &&
                        !link.label.toLowerCase().includes('next')
                    "
                    :href="link.url || ''"
                    v-html="link.label"
                    class="hidden md:block px-3 py-2 leading-tight text-gray-500 bg-white border text-xs md:text-lg rounded-lg border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                    :class="{
                        'bg-white': !link.url,
                        'font-bold text-red-800': link.active,
                    }"
                />
            </template>
        </div>
    </div>
</template>

<style>
.scrollable-div::-webkit-scrollbar {
    width: 14px; /* Adjust the width as needed */
}

.scrollable-div::-webkit-scrollbar-track {
    background-color: #f0f0f0; /* Color of the scrollbar track */
}

.scrollable-div::-webkit-scrollbar-thumb {
    background-color: #8c1515; /* Color of the scrollbar thumb */
    border-radius: 6px; /* Adjust the radius as needed */
}

.scrollable-div::-webkit-scrollbar-thumb:hover {
    background-color: #e11f1f; /* Color of the scrollbar thumb on hover */
}
</style>
