<template>
    <div class="w-[1440px] h-[1570px] relative bg-neutral-100">
        <div
            class="w-[285px] h-[1570px] left-0 top-0 absolute bg-red-800"
        ></div>
        <div class="w-[229px] h-[67px] left-[36px] top-[37px] absolute"></div>
        <div class="w-[1061px] h-[336px] left-[323px] top-[30px] absolute">
            <div
                class="w-[494px] h-[336px] left-0 top-0 absolute bg-white rounded-[10px]"
            ></div>
            <div
                class="w-[543px] h-[336px] left-[518px] top-0 absolute bg-white rounded-[10px]"
            ></div>
            <div class="w-96 h-32 left-[55px] top-[104px] absolute">
                <img
                    class="w-[100px] h-[100px] left-0 top-[14px] absolute rounded-full"
                    src="https://i.pravatar.cc/100"
                />
                <div class="left-[134px] top-0 absolute">
                    <span
                        style="text-stone-900 text-2xl font-normal font-['Source Sans Pro']"
                        >Jane Doe, MD<br /></span
                    ><span
                        style="text-stone-900 text-xl font-normal font-['Source Sans Pro']"
                        >University Tenure Line<br />Oncology - 10 years, 8
                        months<br />Department of Medicine</span
                    >
                </div>
            </div>
            <div
                class="left-[39px] top-[34px] absolute text-red-800 text-3xl font-semibold font-['Source Sans Pro']"
            >
                ABOUT ME
            </div>
            <div class="w-[431px] h-[245px] left-[558px] top-[34px] absolute">
                <div
                    class="w-[174px] h-[172px] left-[33px] top-[73px] absolute"
                >
                    <div class="left-0 top-0 absolute">
                        <span
                            style="text-stone-900 text-2xl font-normal font-['Source Sans Pro']"
                            >Jane Doe Lab<br /></span
                        ><span
                            style="text-stone-900 text-xl font-normal font-['Source Sans Pro']"
                            >12345 Street Ave, <br />Room 678<br />Redwood City,
                            94065</span
                        >
                    </div>
                    <div
                        class="w-[140px] h-[25px] left-0 top-[147px] absolute justify-start items-center gap-2.5 inline-flex"
                    >
                        <div class="w-6 h-6 relative"></div>
                        <div
                            class="text-blue-700 text-xl font-normal font-['Source Sans Pro']"
                        >
                            Visit Website
                        </div>
                    </div>
                </div>
                <div
                    class="w-[174px] h-[172px] left-[257px] top-[73px] absolute"
                >
                    <div class="left-0 top-0 absolute">
                        <span
                            style="text-stone-900 text-2xl font-normal font-['Source Sans Pro']"
                            >Jane Doe Lab<br /></span
                        ><span
                            style="text-stone-900 text-xl font-normal font-['Source Sans Pro']"
                            >12345 Street Ave, <br />Room 678<br />Redwood City,
                            94065</span
                        >
                    </div>
                    <div
                        class="w-[140px] h-[25px] left-0 top-[147px] absolute justify-start items-center gap-2.5 inline-flex"
                    >
                        <div class="w-6 h-6 relative"></div>
                        <div
                            class="text-blue-700 text-xl font-normal font-['Source Sans Pro']"
                        >
                            Visit Website
                        </div>
                    </div>
                </div>
                <div
                    class="left-0 top-0 absolute text-red-800 text-3xl font-semibold font-['Source Sans Pro']"
                >
                    MY LABS
                </div>
            </div>
        </div>
        <div class="w-[1061px] h-[556px] left-[323px] top-[943px] absolute">
            <div class="w-[1061px] h-[556px] left-0 top-0 absolute">
                <div
                    class="w-[1061px] h-[556px] left-0 top-0 absolute bg-white rounded-[10px]"
                ></div>
                <div
                    class="w-[455px] h-[34.35px] left-[39px] top-[30.74px] absolute"
                >
                    <div
                        class="w-[184px] h-[34.35px] left-0 top-0 absolute text-red-800 text-3xl font-semibold font-['Source Sans Pro']"
                    >
                        MY ACTIVITIES
                    </div>
                    <div
                        class="w-5 h-[18.08px] left-[189px] top-[9.04px] absolute"
                    ></div>
                    <div
                        class="w-56 h-[22.60px] left-[231px] top-[11.75px] absolute text-black text-xl font-semibold font-['Source Sans Pro']"
                    >
                        RESEARCH PUBLICATIONS
                    </div>
                </div>
            </div>
            <div class="w-[983px] h-[417px] left-[39px] top-[106px] absolute">
                <div
                    class="w-[983px] h-[63.41px] left-0 top-0 absolute bg-stone-100"
                ></div>
                <div
                    class="w-[30px] h-[17.96px] left-[275px] top-[29.79px] absolute text-black text-base font-normal font-['Source Sans Pro']"
                >
                    Title
                </div>
                <div
                    class="w-[911px] h-[0px] left-[39px] top-[53.81px] absolute border border-black"
                ></div>
                <div
                    class="w-[35px] h-[17.96px] left-[49px] top-[29.79px] absolute text-black text-base font-normal font-['Source Sans Pro']"
                >
                    PMID
                </div>
                <div
                    class="w-[983px] h-[353.59px] left-0 top-[63.41px] absolute"
                >
                    <div class="w-[877px] left-[49px] top-[45.59px] absolute">
                        <div
                            class="w-[651px] h-[30px] left-[226px] top-0 absolute text-black text-xl font-normal font-['Inter']"
                        >
                            Robust enumeration of cell subsets from tissue
                            expression profiles
                        </div>
                        <div
                            class="w-[131px] h-[11.75px] left-0 top-0 absolute text-blue-600 text-xl font-normal font-['Inter']"
                        >
                            32734233
                        </div>
                    </div>
                    <div
                        class="w-[983px] h-[92px] left-0 top-[132.59px] absolute"
                    >
                        <div
                            class="w-[983px] h-[92px] left-0 top-0 absolute bg-stone-100"
                        ></div>
                        <div
                            class="w-[651px] h-[55px] left-[275px] top-[18px] absolute text-black text-xl font-normal font-['Inter']"
                        >
                            Chromatin and gene-regulatory dynamics of the
                            developing human cerebral cortex at single-cell
                            resolution
                        </div>
                        <div
                            class="w-[131px] h-[21.55px] left-[49px] top-[18px] absolute text-blue-600 text-xl font-normal font-['Inter']"
                        >
                            32734233
                        </div>
                    </div>
                    <div class="w-[877px] left-[49px] top-[280.59px] absolute">
                        <div
                            class="w-[651px] h-[52px] left-[226px] top-0 absolute text-black text-xl font-normal font-['Inter']"
                        >
                            Stanford's Biodesign Innovation program: Teaching
                            opportunities for value-driven innovation in surgery
                        </div>
                        <div
                            class="w-[131px] h-[20.37px] left-0 top-0 absolute text-blue-600 text-xl font-normal font-['Inter']"
                        >
                            32734233
                        </div>
                    </div>
                    <div
                        class="w-[983px] h-[92px] left-0 top-[386.59px] absolute"
                    >
                        <div
                            class="w-[983px] h-[92px] left-0 top-0 absolute bg-stone-100"
                        ></div>
                        <div
                            class="w-[651px] h-[50px] left-[275px] top-[21px] absolute text-black text-xl font-normal font-['Inter']"
                        >
                            Systematic analysis of 50 years of Stanford
                            University technology transfer and commercialization
                        </div>
                        <div
                            class="w-[131px] h-[19.59px] left-[49px] top-[21px] absolute text-blue-600 text-xl font-normal font-['Inter']"
                        >
                            32734233
                        </div>
                    </div>
                    <div class="w-[877px] left-[49px] top-[532.59px] absolute">
                        <div
                            class="w-[651px] h-7 left-[226px] top-0 absolute text-black text-xl font-normal font-['Inter']"
                        >
                            What is 'genuine' failure of neuraxial anaesthesia?
                        </div>
                        <div
                            class="w-[131px] h-[10.97px] left-0 top-0 absolute text-blue-600 text-xl font-normal font-['Inter']"
                        >
                            32734233
                        </div>
                    </div>
                    <div
                        class="w-[983px] h-[110px] left-0 top-[619.59px] absolute"
                    >
                        <div
                            class="w-[983px] h-[110px] left-0 top-0 absolute bg-stone-100"
                        ></div>
                        <div
                            class="w-[651px] h-[78px] left-[275px] top-[16px] absolute text-black text-xl font-normal font-['Inter']"
                        >
                            The Stanford Global Health Media Fellowship:
                            Training the Next Generation of Physician
                            Communicators to Fight Health Misinformation
                        </div>
                        <div
                            class="w-[131px] h-[30.56px] left-[49px] top-[16px] absolute text-blue-600 text-xl font-normal font-['Inter']"
                        >
                            32734233
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-[1061px] h-[517px] left-[323px] top-[396px] absolute">
            <div class="w-[543px] h-[517px] left-[518px] top-0 absolute">
                <div
                    class="w-[543px] h-[517px] left-0 top-0 absolute bg-white rounded-[10px]"
                ></div>
                <div
                    class="w-[483px] h-[123px] left-[40px] top-[158px] absolute"
                >
                    <div class="w-36 h-[31px] left-0 top-0 absolute">
                        <div
                            class="w-36 h-[31px] left-0 top-0 absolute bg-stone-400 bg-opacity-60 rounded-[100px]"
                        ></div>
                        <div
                            class="left-[15px] top-[5px] absolute text-center text-black text-base font-normal font-['Source Sans Pro']"
                        >
                            Adenocarcinoma
                        </div>
                    </div>
                    <div class="w-16 h-[31px] left-0 top-[46px] absolute">
                        <div
                            class="w-16 h-[31px] left-0 top-0 absolute bg-stone-400 bg-opacity-60 rounded-[100px]"
                        ></div>
                        <div
                            class="left-[10px] top-[5px] absolute text-center text-black text-base font-normal font-['Source Sans Pro']"
                        >
                            Alleles
                        </div>
                    </div>
                    <div class="w-[153px] h-[31px] left-[154px] top-0 absolute">
                        <div
                            class="w-[153px] h-[31px] left-0 top-0 absolute bg-stone-400 bg-opacity-60 rounded-[100px]"
                        ></div>
                        <div
                            class="left-[18px] top-[5px] absolute text-center text-black text-base font-normal font-['Source Sans Pro']"
                        >
                            Aged, 80 and over
                        </div>
                    </div>
                    <div
                        class="w-[117px] h-[31px] left-[74px] top-[46px] absolute"
                    >
                        <div
                            class="w-[117px] h-[31px] left-0 top-0 absolute bg-stone-400 bg-opacity-60 rounded-[100px]"
                        ></div>
                        <div
                            class="left-[9px] top-[5px] absolute text-center text-black text-base font-normal font-['Source Sans Pro']"
                        >
                            Base Sequence
                        </div>
                    </div>
                    <div class="w-[131px] h-[31px] left-0 top-[92px] absolute">
                        <div
                            class="w-[131px] h-[31px] left-0 top-0 absolute bg-stone-400 bg-opacity-60 rounded-[100px]"
                        ></div>
                        <div
                            class="left-[13px] top-[5px] absolute text-center text-black text-base font-normal font-['Source Sans Pro']"
                        >
                            Squamous Cells
                        </div>
                    </div>
                    <div
                        class="w-[131px] h-[31px] left-[141px] top-[92px] absolute"
                    >
                        <div
                            class="w-[131px] h-[31px] left-0 top-0 absolute bg-stone-400 bg-opacity-60 rounded-[100px]"
                        ></div>
                        <div
                            class="left-[9px] top-[5px] absolute text-center text-black text-base font-normal font-['Source Sans Pro']"
                        >
                            Cell Line, Tumors
                        </div>
                    </div>
                    <div
                        class="w-[137px] h-[31px] left-[201px] top-[46px] absolute"
                    >
                        <div
                            class="w-[137px] h-[31px] left-0 top-0 absolute bg-stone-400 bg-opacity-60 rounded-[100px]"
                        ></div>
                        <div
                            class="left-[9px] top-[5px] absolute text-center text-black text-base font-normal font-['Source Sans Pro']"
                        >
                            Breast Neoplasms
                        </div>
                    </div>
                    <div
                        class="w-[95px] h-[31px] left-[348px] top-[46px] absolute"
                    >
                        <div
                            class="w-[95px] h-[31px] left-0 top-0 absolute bg-stone-400 bg-opacity-60 rounded-[100px]"
                        ></div>
                        <div
                            class="left-[12px] top-[5px] absolute text-center text-black text-base font-normal font-['Source Sans Pro']"
                        >
                            Carcinoma
                        </div>
                    </div>
                    <div class="w-[67px] h-[31px] left-[317px] top-0 absolute">
                        <div
                            class="w-[67px] h-[31px] left-0 top-0 absolute bg-stone-400 bg-opacity-60 rounded-[100px]"
                        ></div>
                        <div
                            class="left-[15px] top-[5px] absolute text-center text-black text-base font-normal font-['Source Sans Pro']"
                        >
                            Aging
                        </div>
                    </div>
                    <div class="w-[89px] h-[31px] left-[394px] top-0 absolute">
                        <div
                            class="w-[89px] h-[31px] left-0 top-0 absolute bg-stone-400 bg-opacity-60 rounded-[100px]"
                        ></div>
                        <div
                            class="left-[10px] top-[5px] absolute text-center text-black text-base font-normal font-['Source Sans Pro']"
                        >
                            Algorithms
                        </div>
                    </div>
                </div>
                <div
                    class="left-[40px] top-[32px] absolute text-red-800 text-3xl font-semibold font-['Source Sans Pro']"
                >
                    RESEARCH INTERESTS
                </div>
                <div class="w-[345px] h-[41px] left-[40px] top-[83px] absolute">
                    <div
                        class="w-[345px] h-[41px] left-0 top-0 absolute bg-neutral-100 rounded-[100px] border border-stone-300"
                    ></div>
                    <div
                        class="left-[25px] top-[10px] absolute text-center text-stone-900 text-opacity-50 text-base font-normal font-['Source Sans Pro']"
                    >
                        Add more tags..
                    </div>
                    <div
                        class="w-[34px] h-[34px] left-[302px] top-[4px] absolute"
                    >
                        <div
                            class="w-[34px] h-[34px] left-0 top-0 absolute bg-pink-700 rounded-full"
                        ></div>
                        <div
                            class="w-6 h-6 left-[5px] top-[5px] absolute"
                        ></div>
                    </div>
                </div>
            </div>
            <div
                class="w-[495px] h-[517px] left-0 top-0 absolute flex-col justify-start items-start inline-flex"
            >
                <div class="w-[495px] h-[517px] bg-white rounded-[10px]"></div>
                <div
                    class="text-red-800 text-3xl font-semibold font-['Source Sans Pro']"
                >
                    MY COLLABORATORS
                </div>
                <div class="h-[90px] relative">
                    <div
                        class="w-[425px] h-[50px] left-0 top-[40px] absolute justify-start items-center gap-[25px] inline-flex"
                    >
                        <img
                            class="w-[50px] h-[50px] rounded-full"
                            src="https://i.pravatar.cc/50"
                        />
                        <img
                            class="w-[50px] h-[50px] rounded-full"
                            src="https://i.pravatar.cc/50"
                        />
                        <img
                            class="w-[50px] h-[50px] rounded-full"
                            src="https://i.pravatar.cc/50"
                        />
                        <img
                            class="w-[50px] h-[50px] rounded-full"
                            src="https://i.pravatar.cc/50"
                        />
                        <img
                            class="w-[50px] h-[50px] rounded-full"
                            src="https://i.pravatar.cc/50"
                        />
                        <img
                            class="w-[50px] h-[50px] rounded-full"
                            src="https://i.pravatar.cc/50"
                        />
                    </div>
                    <div class="w-[108px] h-[25px] left-0 top-0 absolute">
                        <div
                            class="left-0 top-0 absolute text-black text-xl font-semibold font-['Source Sans Pro']"
                        >
                            CURRENT
                        </div>
                        <div
                            class="w-5 h-5 left-[88px] top-[3px] absolute flex-col justify-start items-start inline-flex"
                        ></div>
                    </div>
                </div>
                <div class="w-[200px] h-[90px] relative">
                    <div
                        class="left-0 top-0 absolute text-black text-xl font-semibold font-['Source Sans Pro']"
                    >
                        SEEKING
                    </div>
                    <div class="w-5 h-5 left-[81px] top-[3px] absolute"></div>
                    <div
                        class="w-[200px] h-[50px] left-0 top-[40px] absolute justify-start items-center gap-[25px] inline-flex"
                    >
                        <img
                            class="w-[50px] h-[50px] rounded-full"
                            src="https://i.pravatar.cc/50"
                        />
                        <img
                            class="w-[50px] h-[50px] rounded-full"
                            src="https://i.pravatar.cc/50"
                        />
                        <img
                            class="w-[50px] h-[50px] rounded-full"
                            src="https://i.pravatar.cc/50"
                        />
                    </div>
                </div>
                <div class="relative">
                    <div
                        class="w-[105px] h-[25px] left-0 top-0 absolute justify-start items-center gap-[5px] inline-flex"
                    >
                        <div
                            class="text-black text-xl font-semibold font-['Source Sans Pro']"
                        >
                            REQUESTED
                        </div>
                    </div>
                    <div
                        class="w-[200px] h-[50px] left-0 top-[40px] absolute justify-start items-center gap-[25px] inline-flex"
                    >
                        <img
                            class="w-[50px] h-[50px] rounded-full"
                            src="https://i.pravatar.cc/50"
                        />
                        <img
                            class="w-[50px] h-[50px] rounded-full"
                            src="https://i.pravatar.cc/50"
                        />
                        <img
                            class="w-[50px] h-[50px] rounded-full"
                            src="https://i.pravatar.cc/50"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div
            class="w-[290px] h-[81px] left-0 top-[176px] absolute bg-red-900"
        ></div>
        <div class="w-[200px] h-[407px] left-[35px] top-[30px] absolute">
            <div
                class="w-[127px] h-[190px] left-[36.50px] top-[169px] absolute flex-col justify-start items-start gap-[55px] inline-flex"
            >
                <div class="justify-start items-center gap-[15px] inline-flex">
                    <div class="w-[29px] h-[29px] relative"></div>
                    <div
                        class="text-white text-2xl font-semibold font-['Source Sans Pro']"
                    >
                        Profile
                    </div>
                </div>
                <div class="justify-start items-center gap-[15px] inline-flex">
                    <div class="w-6 h-6 relative"></div>
                    <div
                        class="text-white text-xl font-semibold font-['Source Sans Pro']"
                    >
                        Team Up
                    </div>
                </div>
                <div class="justify-start items-start gap-[15px] inline-flex">
                    <div class="w-6 h-6 relative"></div>
                    <div
                        class="text-white text-xl font-semibold font-['Source Sans Pro']"
                    >
                        Resources
                    </div>
                </div>
            </div>
            <img
                class="w-[58px] h-[66px] left-0 top-[16px] absolute"
                src="https://i.pravatar.cc/50"
            />
            <div
                class="w-[117px] h-16 left-[76px] top-[17px] absolute text-white text-2xl font-normal font-['Source Sans Pro']"
            >
                Team<br />Scientists
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";

console.log("figma test");
</script>
