<script setup>
import { ref, watch } from "vue";
import { Link, router } from "@inertiajs/vue3";
import BarChart from "@/Components/Charts/BarChart.vue";
import DefaultLayout from "@/Layouts/DefaultLayout.vue";
import axios from "axios";

const props = defineProps([
    "divisions",
    "pubs",
    "divisionCount",
    "selectedDivision",
    "selectedYear",
    "counts",
]);

const divisions = ref(props.divisions);
const pubs = ref(props.pubs);
const divisionCount = ref(props.divisionCount);
const selectedDivision = ref(props.selectedDivision);
const selectedYear = ref(props.selectedYear);
const countsTotal = ref(props.counts);

const countData = countsTotal.value;
const divisionData = divisions.value;
const pubData = pubs.value.data;
const pubValues = pubs.value;
const divCount = divisionCount.value;
const pubPaginate = pubValues.links;

const pubFilter = ref([]);
const paginationLinks = ref([]);
const isLoading = ref(false);

const votedPublications = ref(new Set());

function getDivision(division, year) {
    isLoading.value = true;
    let url = "";

    if (division) {
        url = `?division=${division}`;
    } else {
        url = `?division=`;
    }

    if (year) {
        url += `&year=${year}`;
    }

    router.get(url, {
        onSuccess: (dataSet) => {
            pubFilter.value = dataSet.publications;
            selectedDivision.value = division;
            selectedYear.value = year;
            paginationLinks.value = dataSet.paginationLinks;
            isLoading.value = false;
        },
        onError: (error) => {
            console.error(error);
            isLoading.value = false;
        },
    });
}

async function vote(publication) {
    try {
        let response = await axios.post(`/${publication.id}/vote`);
        if (response.data && response.data.votes) {
            publication.votes = response.data.votes;
        }

        votedPublications.value.add(publication.id);
    } catch (error) {
        console.error("Error voting:", error);
    }
}

// Watch function to update pubByDiv when necessary data changes
watch([divCount, selectedDivision, selectedYear], () => {
    pubByDiv = computePubByDiv();
});

const dataYears = _.forEach(countData, function (value, key) {
    return value.pub_year;
});

function computePubByDiv() {
    const pubDiv = divCount.map((item) => item.abbreviation);
    const pubCount = divCount.map((item) => item.total);
    const pubYear = divCount.map((item) => item.pub_year);

    const pubYearAll = dataYears
        .map((item) => String(item.pub_year))
        .sort((a, b) => a.localeCompare(b));
    const pubCountAll = dataYears.map((item) => item.total);

    if (selectedDivision.value === null && selectedYear.value === null) {
        return {
            labels: pubYearAll,
            datasets: [
                {
                    label: "Publications by Year",
                    data: pubCountAll,
                    backgroundColor: "rgb(100, 149, 237)",
                    borderRadius: "5",
                },
            ],
        };
    } else if (selectedDivision.value !== null && selectedYear.value === null) {
        // Combine pubYear and pubCount and sort based on pubYear

        const sortedData = pubYear
            .map((year, index) => ({
                year: year,
                count: pubCount[index],
            }))
            .sort((a, b) => a.year - b.year);

        // Separate back into sorted pubYear and pubCount
        const sortedPubYear = sortedData.map((item) => item.year);
        const sortedPubCount = sortedData.map((item) => item.count);

        return {
            labels: sortedPubYear,
            datasets: [
                {
                    label: "Publications by Year",
                    data: sortedPubCount,
                    backgroundColor: "rgb(100, 149, 237)",
                    borderRadius: "5",
                },
            ],
        };
    } else if (selectedDivision.value !== null && selectedYear.value !== null) {
        const barThickness = 100;

        const sortedData = pubYear
            .map((year, index) => ({
                year: year,
                count: pubCount[index],
            }))
            .sort((a, b) => a.year - b.year);

        // Separate back into sorted pubYear and pubCount
        const sortedPubYear = sortedData.map((item) => item.year);
        const sortedPubCount = sortedData.map((item) => item.count);

        return {
            labels: sortedPubYear,
            datasets: [
                {
                    label: "Publications by Year",
                    data: sortedPubCount,
                    backgroundColor: "rgb(100, 149, 237)",
                    borderRadius: "5",
                    barThickness: barThickness,
                },
            ],
        };
    } else if (selectedDivision.value === null && selectedYear.value !== null) {
        return {
            labels: pubDiv,
            datasets: [
                {
                    label: "Publications by Year",
                    data: pubCount,
                    backgroundColor: "rgb(100, 149, 237)",
                    borderRadius: "5",
                },
            ],
        };
    }
}

function onDivisionChange(event) {
    const selectedValue = event.target.value;
    selectedDivision.value = selectedValue === "" ? null : selectedValue;
    getDivision(selectedDivision.value, selectedYear.value);
}

// Call the computePubByDiv initially to set the initial value of pubByDiv
let pubByDiv = computePubByDiv();

// Before rendering the template, set selectedDivision to an empty string if it's null
if (selectedDivision.value === null) {
    selectedDivision.value = "";
}
</script>

<template>
    <DefaultLayout class="bg-gray-100">
        <div class="w-full max-w-screen-lg mx-auto p-6 bg-gray-100">
            <div class="p-12 bg-white border-b border-gray-400 shadow-md">
                <h1 class="items-center text-gray-900 text-lg md:text-3xl">
                    Publications by Division
                </h1>
                <br />
                <div class="text-sm md:text-lg">
                    <h1>Select Division</h1>
                    <select
                        v-model="selectedDivision"
                        @change="onDivisionChange"
                        placeholder="Select Division"
                        class="placeholder-gray-400 w-36 md:w-full border rounded-lg text-sm md:text-lg focus:border-cardinal-red focus:outline-none focus:ring-0"
                    >
                        <option value="">All Divisions</option>
                        <option
                            v-for="(division, index) in divisionData"
                            :key="index"
                            :value="division.abbreviation"
                        >
                            {{ division.display_name }}
                        </option>
                    </select>
                </div>
                <br />

                <h1 class="items-center text-md md:text-xl text-gray-600">
                    Publications By {{ selectedDivision }}
                </h1>
                <br />

                <div v-if="isLoading">Loading...</div>

                <div v-else>
                    <BarChart v-if="pubByDiv" :data="pubByDiv" />
                </div>

                <br />

                <!-- <h1 class="items-center text-1xl text-gray-600">
                    Division Publications By Faculty
                </h1>
                <br />
                <BarChart />
                <br /> -->
                <h1 class="items-center text-gray-600 text-md md:text-xl">
                    Filter By Year
                </h1>
                <div class="p-6 bg-white">
                    <div
                        class="grid gap-2 grid-cols-4 md:gap-4 md:grid-cols-6 items-center"
                    >
                        <div v-for="(btn, index) in countData" :key="index">
                            <button
                                type="button"
                                class="bg-gray-300 hover:bg-cardinal-red text-black-700 hover:text-white text-xs w-12 h-4 md:w-24 md:h-10 rounded-full md:text-xl"
                                @click="
                                    getDivision(selectedDivision, btn.pub_year)
                                "
                                v-bind:class="{
                                    'bg-red-800 text-white':
                                        selectedYear == btn.pub_year,
                                }"
                            >
                                {{ btn.pub_year }}
                            </button>
                        </div>
                        <button
                            type="button"
                            class="bg-gray-300 hover:bg-cardinal-red text-black-700 overflow-auto hover:text-white text-xs w-12 h-4 md:w-24 md:h-10 rounded-full md:text-xl"
                            @click="getDivision(selectedDivision, null)"
                            value=""
                            v-bind:class="{
                                'bg-red-800 text-white': selectedYear === null,
                            }"
                        >
                            All Years
                        </button>
                    </div>
                </div>
                <br />
                <br />
                <div>
                    <h1 class="items-center text-gray-600 text-md md:text-xl">
                        Publications
                    </h1>
                    <table
                        class="min-w-full mt-4 bg-white divide-y divide-gray-200"
                    >
                        <thead class="bg-gray-50">
                            <tr
                                class="px-6 py-12 p-6 text-xs md:text-base font-medium tracking-wider text-left text-gray-500 uppercase"
                            >
                                <th>Title</th>
                                <th>pmid</th>
                                <th>year</th>
                                <!-- <th>votes</th> -->
                            </tr>
                        </thead>

                        <tbody class="bg-white divide-y divide-gray-200">
                            <tr v-for="(x, index) in pubData" :key="index">
                                <td
                                    class="text-xs md:max-w-sm md:text-lg py-2 pr-6"
                                >
                                    <p v-html="x.title" />
                                </td>
                                <td class="w-24">
                                    <a
                                        class="text-blue-600 underline hover:blue text-center text-xs md:text-lg"
                                        :href="
                                            'https://pubmed.ncbi.nlm.nih.gov/' +
                                            x.pmid
                                        "
                                        target="_blank"
                                        >{{ x.pmid }}</a
                                    >
                                </td>
                                <td class="text-xs md:text-lg">
                                    {{ x.pub_year }}
                                </td>
                                <!-- <td class="text-xs md:text-lg">
                                    <button
                                        @click="vote(x)"
                                        :disabled="votedPublications.has(x.id)"
                                        class="px-2 py-1 bg-gray-100 rounded-lg hover:bg-gray-300"
                                    >
                                        👍 {{ x.votes || 0 }}
                                    </button>
                                </td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- Pagination -->
                <div class="flex justify-center items-center py-8">
                    <template v-for="(link, index) in pubPaginate" :key="index">
                        <!-- For mobile, only show Previous and Next -->
                        <Link
                            v-if="
                                link.label.toLowerCase().includes('previous') ||
                                link.label.toLowerCase().includes('next')
                            "
                            :href="link.url || ''"
                            v-html="link.label"
                            class="px-3 py-2 leading-tight text-gray-500 text-sm md:text-md bg-white border rounded-lg border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                            :class="{
                                'font-bold text-red-800': link.active,
                            }"
                        />

                        <!-- For desktop and larger, show all pagination links -->
                        <Link
                            v-else-if="
                                !link.label
                                    .toLowerCase()
                                    .includes('previous') &&
                                !link.label.toLowerCase().includes('next')
                            "
                            :href="link.url || ''"
                            v-html="link.label"
                            class="hidden md:block px-3 py-2 leading-tight text-gray-500 text-sm md:text-md bg-white border rounded-lg border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                            :class="{
                                'bg-white': !link.url,
                                'font-bold text-red-800': link.active,
                            }"
                        />
                    </template>
                </div>
            </div>
        </div>
    </DefaultLayout>
</template>
