<script setup>
import { ref, watch, onMounted, watchEffect, onUnmounted } from "vue";
import { Link, router } from "@inertiajs/vue3";

const props = defineProps([
    "pubs",
    "facultyCount",
    "facultyInput",
    "selectedYear",
    "counts",
    "faculty",
]);

const pubs = ref(props.pubs);
const facultyCount = ref(props.facultyCount);
const facultyInput = ref(props.facultyInput);
const selectedYear = ref(props.selectedYear);
const countsTotal = ref(props.counts);
const facultyList = ref(props.faculty);

const pubData = pubs.value.data;
const pubValues = pubs.value;
const pubPaginate = pubValues.links;
const countData = countsTotal.value;
const facCount = facultyCount.value;
const facList = facultyList.value;

const pubFilter = ref([]);
const paginationLinks = ref([]);

const suggestions = ref([]);
const searchInputRef = ref(null);
const suggestionListRef = ref(null);

const votedPublications = ref(new Set());

const selectedFilter = ref("");

const spotlightFilter = ref(
    new URLSearchParams(window.location.search).has("spotlight")
);
const featuredFilter = ref(
    new URLSearchParams(window.location.search).has("featured")
);

const clearFilters = () => {
    spotlightFilter.value = false;
    featuredFilter.value = false;
    facultyInput.value = "";
    selectedYear.value = null;
    searchFaculty();
};

const updateFilters = () => {
    spotlightFilter.value = selectedFilter.value === "spotlight";
    featuredFilter.value = selectedFilter.value === "featured";
    searchFaculty();
};

const facultyNames = _.uniq(
    facList.map((item) => {
        const fullName = [item.first_name, item.last_name]
            .filter(Boolean) // Remove empty values (null or undefined)
            .map((namePart) => namePart.toLowerCase())
            .join(" ");
        return fullName;
    })
);

const selectSuggestion = (suggestion) => {
    facultyInput.value = suggestion;
    suggestions.value = []; // Clear suggestions after selecting one
    searchFaculty(facultyInput.value); // Perform search with autofilled suggestion
};

const searchFaculty = (name, year) => {
    let url = "";

    if (name) {
        url = `?faculty=${name}`;
    } else {
        url = `?faculty=`;
    }

    if (year) {
        url += `&year=${year}`;
    }

    if (spotlightFilter.value) {
        url += `&spotlight=1`;
    }

    if (featuredFilter.value) {
        url += `&featured=1`;
    }

    router.get(url, {
        onSuccess: (dataSet) => {
            facultyInput.value = dataSet.facultyInput;
            pubFilter.value = dataSet.publications;
            selectedYear.value = dataSet.selectedYear;
            paginationLinks.value = dataSet.paginationLinks;
        },
        onError: (error) => {
            console.error(error);
        },
    });
};

async function vote(publication) {
    try {
        let response = await axios.post(`/${publication.id}/vote`);
        if (response.data && response.data.votes) {
            publication.votes = response.data.votes;
        }

        votedPublications.value.add(publication.id);
    } catch (error) {
        console.error("Error voting:", error);
    }
}

async function voteDown(publication) {
    try {
        let response = await axios.post(`/${publication.id}/voteDown`);
        if (response.data && response.data.hasOwnProperty("votes")) {
            publication.votes = response.data.votes;
        }

        votedPublications.value.add(publication.id);
    } catch (error) {
        console.error("Error voting:", error);
    }
}

function updateTitleValue(event, publication) {
    publication.short_title = event.target.value;
}

async function saveShortTitle(publication) {
    try {
        let response = await axios.post(`/${publication.id}/updateShortTitle`, {
            short_title: publication.short_title,
        });
        if (response.data && response.data.success) {
            console.log("Short title updated successfully!");
        }
    } catch (error) {
        console.error("Error updating short title:", error);
    }
}

function updateAbstractValue(event, publication) {
    publication.c_abstract = event.target.value;
}

async function saveAbstract(publication) {
    try {
        let response = await axios.post(`/${publication.id}/updateAbstract`, {
            c_abstract: publication.c_abstract,
        });
        if (response.data && response.data.success) {
            console.log("Abstract updated successfully!");
        }
    } catch (error) {
        console.error("Error updating abstract:", error);
    }
}

const spot = async (item) => {
    item.is_spotlight = item.is_spotlight === 0 ? 1 : 0;
    try {
        await axios.post("/api/update-spotlight", {
            id: item.id,
            is_spotlight: item.is_spotlight,
        });
    } catch (error) {
        item.is_spotlight = item.is_spotlight === 0 ? 1 : 0;
    }
};

const featured = async (item) => {
    item.is_featured = item.is_featured === 0 ? 1 : 0;
    try {
        await axios.post("/api/update-featured", {
            id: item.id,
            is_featured: item.is_featured,
        });
    } catch (error) {
        item.is_featured = item.is_featured === 0 ? 1 : 0;
    }
};

watch(facultyInput, (newInput) => {
    const matchedNames = facultyNames.filter((name) =>
        name.toLowerCase().includes(newInput.toLowerCase())
    );
    suggestions.value = matchedNames;
});

onMounted(() => {
    document.addEventListener("click", handleDocumentClick);
});

onUnmounted(() => {
    document.removeEventListener("click", handleDocumentClick);
});

const handleDocumentClick = (event) => {
    const isClickedOutside =
        searchInputRef.value &&
        !searchInputRef.value.contains(event.target) &&
        suggestionListRef.value &&
        !suggestionListRef.value.contains(event.target);

    if (isClickedOutside) {
        suggestions.value = [];
    }
};

// console.log(pubData);
</script>

<template>
    <div class="mx-auto p-6 bg-gray-100">
        <div class="p-12 bg-white border-b border-gray-400 shadow-md">
            <h1 class="items-center text-gray-900 text-md md:text-3xl">
                Spotlight and Featured Publications Voting
            </h1>
            <br />
            <div>
                <input
                    type="text"
                    v-model="facultyInput"
                    ref="searchInput"
                    placeholder="Enter First, Last, or Full Name of Faculty or PMID"
                    class="w-3/4 h-10 px-3 text-sm md:text-base placeholder-gray-400 border rounded-lg focus:border-cardinal-red focus:outline-none focus:ring-0"
                    @keyup.enter="searchFaculty(facultyInput)"
                />
                <button
                    class="w-1/4 h-10 px-3 text-sm md:text-base text-white bg-cardinal-red rounded-lg hover:caret-cardinal-red"
                    @click="searchFaculty(facultyInput)"
                >
                    Search
                </button>
            </div>

            <div
                v-if="suggestions.length"
                ref="suggestionList"
                class="z-10 absolute mt-1 bg-white border border-gray-300 shadow-md rounded-lg max-h-40 overflow-y-auto"
            >
                <ul class="space-y-1">
                    <li
                        v-for="suggestion in suggestions.slice(0, 10)"
                        :key="suggestion"
                        class="cursor-pointer px-3 py-1 hover:bg-gray-100"
                        @click="selectSuggestion(suggestion)"
                    >
                        {{ suggestion }}
                    </li>
                </ul>
            </div>
            <br />
            <h1 class="items-center text-gray-600 text-md md:text-xl">
                Filter By Year
            </h1>
            <div class="p-6 bg-white">
                <div
                    class="grid gap-2 grid-cols-4 md:gap-4 md:grid-cols-7 items-center"
                >
                    <div v-for="(btn, index) in countData" :key="index">
                        <button
                            type="button"
                            class="bg-gray-300 hover:bg-cardinal-red text-black-700 hover:text-white text-xs w-12 h-4 md:w-32 md:h-14 rounded-full md:text-xl"
                            @click="searchFaculty(facultyInput, btn.pub_year)"
                            v-bind:class="{
                                'bg-red-800 text-white':
                                    selectedYear == btn.pub_year,
                            }"
                        >
                            {{ btn.pub_year }}
                        </button>
                    </div>
                    <button
                        type="button"
                        class="bg-gray-300 hover:bg-cardinal-red text-black-700 overflow-auto hover:text-white text-xs w-12 h-4 md:w-32 md:h-14 rounded-full md:text-xl"
                        @click="searchFaculty(facultyInput, null)"
                        value=""
                        v-bind:class="{
                            'bg-red-800 text-white': selectedYear === null,
                        }"
                    >
                        All Years
                    </button>
                </div>
            </div>
            <br />
            <div class="filters flex justify-center">
                <h1>Filter by Current:</h1>
                <select
                    v-model="selectedFilter"
                    @change="updateFilters"
                    class="placeholder-gray-400 w-36 md:w-full border rounded-lg text-sm md:text-lg focus:border-cardinal-red focus:outline-none focus:ring-0"
                >
                    <option value="">Select filter</option>
                    <option value="spotlight">Spotlight</option>
                    <option value="featured">Featured</option>
                </select>
                <button
                    @click="clearFilters()"
                    class="px-3 mx-2 text-sm md:text-base text-white bg-cardinal-red rounded-lg hover:caret-cardinal-red"
                >
                    Clear Filters
                </button>
            </div>
            <br />
            <div class="text-center mt-4">
                <a
                    href="/featuredPub"
                    target="_blank"
                    class="md:text-base text-white bg-cardinal-red rounded-lg hover:caret-cardinal-red p-4"
                    >Go to Featured Publications</a
                >
            </div>
            <br />

            <div>
                <h1 class="items-center text-gray-600 text-xl">Publications</h1>
                <table
                    class="md:min-w-full mt-4 bg-white divide-y divide-gray-200"
                >
                    <thead class="bg-gray-50">
                        <tr
                            class="py-12 p-6 text-xs md:text-base font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                            <th>Author</th>
                            <th>Title</th>
                            <th>abstract</th>
                            <th class="px-4">pmid</th>
                            <th class="px-4">date</th>
                            <th class="px-4">votes</th>
                            <th>spub</th>
                            <th>fpub</th>
                        </tr>
                    </thead>

                    <tbody class="bg-white divide-y divide-gray-200">
                        <tr v-for="(x, index) in pubData" :key="index">
                            <td
                                class="text-xs md:w-40 md:max-w-sm md:text-lg py-2 pr-2"
                            >
                                {{ x.first_name + " " + x.last_name }}
                            </td>
                            <!-- <td
                                class="text-xs md:max-w-sm md:text-lg py-2 pr-6"
                            >
                                <p v-html="x.title" />
                            </td> -->
                            <td class="text-xs md:text-lg py-2 pr-2">
                                <textarea
                                    :value="x.short_title || x.title"
                                    @input="updateTitleValue($event, x)"
                                    @blur="saveShortTitle(x)"
                                    class="border rounded px-1 py-1 md:w-80 resize"
                                    rows="3"
                                ></textarea>
                            </td>
                            <td class="text-xs md:text-lg py-2 pr-2">
                                <textarea
                                    :value="x.c_abstract || x.abstract"
                                    @input="updateAbstractValue($event, x)"
                                    @blur="saveAbstract(x)"
                                    class="border rounded px-1 py-1 md:w-80 resize"
                                    rows="3"
                                ></textarea>
                            </td>
                            <td>
                                <a
                                    class="text-blue-600 underline hover:blue text-center text-xs md:text-lg"
                                    :href="
                                        'https://pubmed.ncbi.nlm.nih.gov/' +
                                        x.pmid
                                    "
                                    target="_blank"
                                    >{{ x.pmid }}</a
                                >
                            </td>
                            <td class="text-xs md:text-lg px-2">
                                {{ x.pub_date }}
                            </td>
                            <td class="text-xs md:text-lg px-4">
                                <button
                                    @click="vote(x)"
                                    class="px-2 py-1 bg-gray-100 rounded-lg hover:bg-gray-300"
                                >
                                    👍
                                </button>
                                <button
                                    @click="voteDown(x)"
                                    class="px-2 py-1 bg-gray-100 rounded-lg hover:bg-gray-300"
                                >
                                    👎
                                </button>
                                {{ x.votes || 0 }}
                            </td>
                            <td class="px-2">
                                <button
                                    @click="spot(x)"
                                    class="px-2 py-1 bg-gray-100 rounded-lg hover:bg-gray-300"
                                >
                                    {{ x.is_spotlight }}
                                </button>
                            </td>
                            <td class="px-2">
                                <button
                                    @click="featured(x)"
                                    class="px-2 py-1 bg-gray-100 rounded-lg hover:bg-gray-300"
                                >
                                    {{ x.is_featured }}
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- Pagination -->
            <div class="flex justify-center items-center py-8">
                <template v-for="(link, index) in pubPaginate" :key="index">
                    <!-- For mobile, only show Previous and Next -->
                    <Link
                        v-if="
                            link.label.toLowerCase().includes('previous') ||
                            link.label.toLowerCase().includes('next')
                        "
                        :href="link.url || ''"
                        v-html="link.label"
                        class="px-3 py-2 leading-tight text-xs md:text-lg text-gray-500 bg-white border rounded-lg border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                        :class="{
                            'font-bold text-red-800': link.active,
                        }"
                    />

                    <!-- For desktop and larger, show all pagination links -->
                    <Link
                        v-else-if="
                            !link.label.toLowerCase().includes('previous') &&
                            !link.label.toLowerCase().includes('next')
                        "
                        :href="link.url || ''"
                        v-html="link.label"
                        class="hidden md:block px-3 py-2 leading-tight text-gray-500 bg-white border text-xs md:text-lg rounded-lg border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                        :class="{
                            'bg-white': !link.url,
                            'font-bold text-red-800': link.active,
                        }"
                    />
                </template>
            </div>
        </div>
    </div>
</template>
