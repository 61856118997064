<script setup>
import { ref, computed, watch, onMounted, onUnmounted } from "vue";
import { Link, router } from "@inertiajs/vue3";
import BarChart from "@/Components/Charts/BarChart.vue";
import DefaultLayout from "@/Layouts/DefaultLayout.vue";

const props = defineProps([
    "pubs",
    "facultyCount",
    "facultyInput",
    "selectedYear",
    "counts",
    "faculty",
]);

const pubs = ref(props.pubs);
const facultyCount = ref(props.facultyCount);
const facultyInput = ref(props.facultyInput);
const selectedYear = ref(props.selectedYear);
const countsTotal = ref(props.counts);
const facultyList = ref(props.faculty);

const pubData = pubs.value.data;
const pubValues = pubs.value;
const pubPaginate = pubValues.links;
const countData = countsTotal.value;
const facCount = facultyCount.value;
const facList = facultyList.value;

const pubFilter = ref([]);
const paginationLinks = ref([]);

const suggestions = ref([]);
const searchInputRef = ref(null);
const suggestionListRef = ref(null);

const votedPublications = ref(new Set());

const facultyNames = _.uniq(
    facList.map((item) => {
        const fullName = [item.first_name, item.last_name]
            .filter(Boolean) // Remove empty values (null or undefined)
            .map((namePart) => namePart.toLowerCase())
            .join(" ");
        return fullName;
    })
);

const selectSuggestion = (suggestion) => {
    facultyInput.value = suggestion;
    suggestions.value = []; // Clear suggestions after selecting one
    searchFaculty(facultyInput.value); // Perform search with autofilled suggestion
};

function searchFaculty(name, year) {
    let url = "";

    if (name) {
        url = `?faculty=${name}`;
    } else {
        url = `?faculty=`;
    }

    if (year) {
        url += `&year=${year}`;
    }

    router.get(url, {
        onSuccess: (dataSet) => {
            facultyInput.value = name;
            pubFilter.value = dataSet.publications;
            selectedYear.value = year;
            paginationLinks.value = dataSet.paginationLinks;
        },
        onError: (error) => {
            console.error(error);
        },
    });
}

async function vote(publication) {
    try {
        let response = await axios.post(`/${publication.id}/vote`);
        if (response.data && response.data.votes) {
            publication.votes = response.data.votes;
        }

        votedPublications.value.add(publication.id);
    } catch (error) {
        console.error("Error voting:", error);
    }
}

watch(facultyInput, (newInput) => {
    const matchedNames = facultyNames.filter((name) =>
        name.toLowerCase().includes(newInput.toLowerCase())
    );
    suggestions.value = matchedNames;
});

const dataYears = _.forEach(countData, function (value, key) {
    return value.pub_year;
});

const facultyTotal = computed(() => {
    const pubYearAll = dataYears
        .map((item) => String(item.pub_year))
        .sort((a, b) => a.localeCompare(b));
    const pubCountAll = dataYears.map((item) => item.total);

    const pubYear = facCount.map((item) => item.pub_year);
    const pubCount = facCount.map((item) => item.total);

    const facultyFirst = _.uniq(
        facCount.map((item) => item.first_name.toLowerCase())
    );
    const facultyLast = _.uniq(
        facCount.map((item) => item.last_name.toLowerCase())
    );

    if (facultyInput.value === null) {
        return {
            labels: pubYearAll,
            datasets: [
                {
                    label: "Publications by Years",
                    data: pubCountAll,
                    backgroundColor: "rgb(100, 149, 237)",
                    borderRadius: "5",
                },
            ],
        };
    } else if (
        facultyInput.value.toLowerCase() == facultyFirst &&
        selectedYear.value === null
    ) {
        const sortedData = pubYear
            .map((year, index) => ({
                year: year,
                count: pubCount[index],
            }))
            .sort((a, b) => a.year - b.year);

        // Separate back into sorted pubYear and pubCount
        const sortedPubYear = sortedData.map((item) => item.year);
        const sortedPubCount = sortedData.map((item) => item.count);

        return {
            labels: sortedPubYear,
            datasets: [
                {
                    label:
                        "Publications by " + facultyFirst + " " + facultyLast,
                    data: sortedPubCount,
                    backgroundColor: "rgb(100, 149, 237)",
                    borderRadius: "5",
                },
            ],
        };
    } else if (
        facultyInput.value.toLowerCase() == facultyFirst + " " + facultyLast &&
        selectedYear.value === null
    ) {
        const sortedData = pubYear
            .map((year, index) => ({
                year: year,
                count: pubCount[index],
            }))
            .sort((a, b) => a.year - b.year);

        // Separate back into sorted pubYear and pubCount
        const sortedPubYear = sortedData.map((item) => item.year);
        const sortedPubCount = sortedData.map((item) => item.count);

        return {
            labels: sortedPubYear,
            datasets: [
                {
                    label:
                        "Publications by " + facultyFirst + " " + facultyLast,
                    data: sortedPubCount,
                    backgroundColor: "rgb(100, 149, 237)",
                    borderRadius: "5",
                },
            ],
        };
    } else if (
        facultyInput.value.toLowerCase() == facultyLast &&
        selectedYear.value === null
    ) {
        const sortedData = pubYear
            .map((year, index) => ({
                year: year,
                count: pubCount[index],
            }))
            .sort((a, b) => a.year - b.year);

        // Separate back into sorted pubYear and pubCount
        const sortedPubYear = sortedData.map((item) => item.year);
        const sortedPubCount = sortedData.map((item) => item.count);

        return {
            labels: sortedPubYear,
            datasets: [
                {
                    label:
                        "Publications by " + facultyFirst + " " + facultyLast,
                    data: sortedPubCount,
                    backgroundColor: "rgb(100, 149, 237)",
                    borderRadius: "5",
                },
            ],
        };
    } else if (facultyInput.value !== null && selectedYear.value !== null) {
        const barThickness = 100;
        return {
            labels: pubYear,
            datasets: [
                {
                    label:
                        "Publications by " + facultyFirst + " " + facultyLast,
                    data: pubCount,
                    backgroundColor: "rgb(100, 149, 237)",
                    borderRadius: "5",
                    barThickness: barThickness,
                },
            ],
        };
    } else {
        return {
            labels: "",
            datasets: [
                {
                    label: "Publications by Year",
                    data: "",
                    backgroundColor: "rgb(100, 149, 237)",
                    borderRadius: "5",
                },
            ],
        };
    }
});

onMounted(() => {
    // Add click event listener to the document
    document.addEventListener("click", handleDocumentClick);
});

onUnmounted(() => {
    // Remove the event listener when the component is unmounted
    document.removeEventListener("click", handleDocumentClick);
});

const handleDocumentClick = (event) => {
    const isClickedOutside =
        searchInputRef.value &&
        !searchInputRef.value.contains(event.target) &&
        suggestionListRef.value &&
        !suggestionListRef.value.contains(event.target);

    if (isClickedOutside) {
        suggestions.value = []; // Clear suggestions
    }
};

// console.log(pubData);
</script>

<template>
    <DefaultLayout class="bg-gray-100">
        <div class="w-full max-w-screen-lg mx-auto p-6 bg-gray-100">
            <div class="p-12 bg-white border-b border-gray-400 shadow-md">
                <h1 class="items-center text-gray-900 text-md md:text-3xl">
                    Publications by Faculty
                </h1>
                <br />
                <div>
                    <input
                        type="text"
                        v-model="facultyInput"
                        ref="searchInput"
                        placeholder="Enter First, Last, or Full Name of Faculty"
                        class="w-3/4 h-10 px-3 text-sm md:text-base placeholder-gray-400 border rounded-lg focus:border-cardinal-red focus:outline-none focus:ring-0"
                        @keyup.enter="searchFaculty(facultyInput)"
                    />
                    <button
                        class="w-1/4 h-10 px-3 text-sm md:text-base text-white bg-cardinal-red rounded-lg hover:caret-cardinal-red"
                        @click="searchFaculty(facultyInput)"
                    >
                        Search
                    </button>
                </div>

                <div
                    v-if="suggestions.length"
                    ref="suggestionList"
                    class="z-10 absolute mt-1 bg-white border border-gray-300 shadow-md rounded-lg max-h-40 overflow-y-auto"
                >
                    <ul class="space-y-1">
                        <li
                            v-for="suggestion in suggestions.slice(0, 10)"
                            :key="suggestion"
                            class="cursor-pointer px-3 py-1 hover:bg-gray-100"
                            @click="selectSuggestion(suggestion)"
                        >
                            {{ suggestion }}
                        </li>
                    </ul>
                </div>

                <br />
                <div>
                    <BarChart :data="facultyTotal" />
                </div>
                <br />
                <h1 class="items-center text-gray-600 text-md md:text-xl">
                    Filter By Year
                </h1>
                <div class="p-6 bg-white">
                    <div
                        class="grid gap-2 grid-cols-4 md:gap-4 md:grid-cols-6 items-center"
                    >
                        <div v-for="(btn, index) in countData" :key="index">
                            <button
                                type="button"
                                class="bg-gray-300 hover:bg-cardinal-red text-black-700 hover:text-white text-xs w-12 h-4 md:w-24 md:h-10 rounded-full md:text-xl"
                                @click="
                                    searchFaculty(facultyInput, btn.pub_year)
                                "
                                v-bind:class="{
                                    'bg-red-800 text-white':
                                        selectedYear == btn.pub_year,
                                }"
                            >
                                {{ btn.pub_year }}
                            </button>
                        </div>
                        <button
                            type="button"
                            class="bg-gray-300 hover:bg-cardinal-red text-black-700 overflow-auto hover:text-white text-xs w-12 h-4 md:w-24 md:h-10 rounded-full md:text-xl"
                            @click="searchFaculty(facultyInput, null)"
                            value=""
                            v-bind:class="{
                                'bg-red-800 text-white': selectedYear === null,
                            }"
                        >
                            All Years
                        </button>
                    </div>
                </div>
                <br />
                <br />
                <div>
                    <h1 class="items-center text-gray-600 text-xl">
                        Publications
                    </h1>
                    <table
                        class="min-w-full mt-4 bg-white divide-y divide-gray-200"
                    >
                        <thead class="bg-gray-50">
                            <tr
                                class="px-6 py-12 p-6 text-xs md:text-base font-medium tracking-wider text-left text-gray-500 uppercase"
                            >
                                <!-- <th>Author</th> -->
                                <th>Title</th>
                                <th>pmid</th>
                                <th>year</th>
                                <!-- <th>votes</th> -->
                            </tr>
                        </thead>

                        <tbody class="bg-white divide-y divide-gray-200">
                            <tr v-for="(x, index) in pubData" :key="index">
                                <!-- <td>{{ x.first_name + " " + x.last_name }}</td> -->
                                <td
                                    class="text-xs md:max-w-sm md:text-lg py-2 pr-6"
                                >
                                    <p v-html="x.title" />
                                </td>
                                <td>
                                    <a
                                        class="text-blue-600 underline hover:blue text-center text-xs md:text-lg"
                                        :href="
                                            'https://pubmed.ncbi.nlm.nih.gov/' +
                                            x.pmid
                                        "
                                        target="_blank"
                                        >{{ x.pmid }}</a
                                    >
                                </td>
                                <td class="text-xs md:text-lg">
                                    {{ x.pub_year }}
                                </td>
                                <!-- <td class="text-xs md:text-lg">
                                    <button
                                        @click="vote(x)"
                                        :disabled="votedPublications.has(x.id)"
                                        class="px-2 py-1 bg-gray-100 rounded-lg hover:bg-gray-300"
                                    >
                                        👍 {{ x.votes || 0 }}
                                    </button>
                                </td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- Pagination -->
                <div class="flex justify-center items-center py-8">
                    <template v-for="(link, index) in pubPaginate" :key="index">
                        <!-- For mobile, only show Previous and Next -->
                        <Link
                            v-if="
                                link.label.toLowerCase().includes('previous') ||
                                link.label.toLowerCase().includes('next')
                            "
                            :href="link.url || ''"
                            v-html="link.label"
                            class="px-3 py-2 leading-tight text-xs md:text-lg text-gray-500 bg-white border rounded-lg border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                            :class="{
                                'font-bold text-red-800': link.active,
                            }"
                        />

                        <!-- For desktop and larger, show all pagination links -->
                        <Link
                            v-else-if="
                                !link.label
                                    .toLowerCase()
                                    .includes('previous') &&
                                !link.label.toLowerCase().includes('next')
                            "
                            :href="link.url || ''"
                            v-html="link.label"
                            class="hidden md:block px-3 py-2 leading-tight text-gray-500 bg-white border text-xs md:text-lg rounded-lg border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                            :class="{
                                'bg-white': !link.url,
                                'font-bold text-red-800': link.active,
                            }"
                        />
                    </template>
                </div>
            </div>
        </div>
    </DefaultLayout>
</template>
