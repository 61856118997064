import "./bootstrap";
import "../css/app.css";

import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import { ZiggyVue } from "./vendor/tightenco/ziggy/dist/vue.m";
import InstantSearch from "vue-instantsearch/vue3/es";

const appName =
    window.document.getElementsByTagName("title")[0]?.innerText || "Laravel";

createInertiaApp({
    title: (title) => `${appName} ${title ? "- " + title : ""}`,
    resolve: (name) => {
        let page = require(`./Pages/${name}.vue`);
        // page.default.layout = page.default.layout || DefaultLayout;

        return page;
    },
    setup({ el, App, props, plugin }) {
        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(InstantSearch)
            .mount(el);
    },
});
