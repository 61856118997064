<script setup>
import { ref } from "vue";
import { Link, router } from "@inertiajs/vue3";

const props = defineProps({
    profile: Object,
    filters: Object,
    divisions: Array,
    ranks: Array,
    lines: Array,
});
const profileProp = ref(props.profile);
const divProp = ref(props.divisions);
const rankProp = ref(props.ranks);
const lineProp = ref(props.lines);

const facultyProfiles = profileProp.value.data;
const paginateProfiles = profileProp.value.links;
const divPropData = divProp.value;
const ranksProp = rankProp.value;
const linesProp = lineProp.value;

const ranks = _.orderBy(ranksProp, "sort_order", "asc");
const lines = _.orderBy(linesProp, "sort_order", "asc");

const rankOrder = _.uniqBy(ranks, "level").map((rank) => rank.level);
const lineOrder = _.uniqBy(lines, "name").map((line) => line.name);

const selectedDivision = ref(props.filters.division || "");
const search = ref(props.filters.search || "");
const selectedRank = ref(props.filters.rank || "");
const selectedLine = ref(props.filters.line || "");

const updateFilters = () => {
    const queryParams = new URLSearchParams();

    if (search.value) {
        queryParams.append("search", search.value);
    }
    if (selectedDivision.value !== "") {
        queryParams.append("division", selectedDivision.value);
    }
    if (selectedRank.value !== "") {
        queryParams.append("rank", selectedRank.value);
    }
    if (selectedLine.value !== "") {
        queryParams.append("line", selectedLine.value);
    }

    router.get(`/profiles?${queryParams.toString()}`, {
        preserveState: true,
        replace: true,
    });
};
</script>

<template>
    <div class="bg-gray-100">
        <div class="flex justify-center py-6 text-black-60">
            <h1>
                *Type in Faculty name and press "enter" to search. Clear name
                and press "enter" to revert to all faculty.
            </h1>
        </div>
        <div class="pb-6">
            <div class="max-w-screen-xl mx-auto sm:px-6 lg:px-8">
                <div
                    class="grid md:grid-cols-4 gap-4 bg-white overflow-hidden shadow-sm sm:rounded-lg sm:grid-cols-2 sm:grid-row-2"
                >
                    <input
                        v-model="search"
                        type="text"
                        placeholder="Search by Faculty Name"
                        class="p-6 bg-white border-0 focus:border-2 focus:border-cardinal-red focus:rounded-l-lg text-lg focus:outline-none focus:ring-0"
                        @keyup.enter="updateFilters"
                    />

                    <div class="p-2">
                        <label
                            for="countries"
                            class="block mb-2 text-sm font-medium text-gray-900"
                            >Select Division</label
                        >
                        <select
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-cardinal-red focus:outline-none focus:ring-0 block w-full p-2.5"
                            v-model="selectedDivision"
                            @change="updateFilters"
                        >
                            <option value="">All Divisions</option>
                            <option
                                v-for="division in divPropData"
                                :value="division.id"
                                :key="division.id"
                            >
                                {{ division.display_name }}
                            </option>
                        </select>
                    </div>

                    <div class="p-2">
                        <label
                            for="countries"
                            class="block mb-2 text-sm font-medium text-gray-900"
                            >Select Rank</label
                        >
                        <select
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-cardinal-red focus:outline-none focus:ring-0 block w-full p-2.5"
                            v-model="selectedRank"
                            @change="updateFilters"
                        >
                            <option value="">All Rank Levels</option>
                            <option
                                v-for="rank in rankOrder"
                                :value="rank"
                                :key="rank"
                            >
                                {{ rank }}
                            </option>
                        </select>
                    </div>

                    <div class="p-2">
                        <label
                            for="countries"
                            class="block mb-2 text-sm font-medium text-gray-900"
                            >Select Line</label
                        >
                        <select
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-cardinal-red focus:outline-none focus:ring-0 block w-full p-2.5"
                            v-model="selectedLine"
                            @change="updateFilters"
                        >
                            <option value="">All Lines</option>
                            <option
                                v-for="line in lineOrder"
                                :value="line"
                                :key="line"
                            >
                                {{ line }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex max-w-screen-2xl mx-auto flex-wrap justify-center">
            <div
                class="p-2"
                v-for="(pcard, index) in facultyProfiles"
                :key="index"
            >
                <div
                    class="overflow-hidden justify-center md:w-80 md:h-60 md:pt-6 bg-white rounded-lg shadow-lg sm:w-36 sm:h-56 sm:pt-4 xs:w-36 xs:h-56 xs:pt-4"
                >
                    <div v-if="pcard.stanford_profile">
                        <a :href="pcard.cap_url" target="_blank">
                            <img
                                v-if="pcard.stanford_profile.photo_url_350"
                                :src="pcard.stanford_profile.photo_url_350"
                                alt="Profile Image"
                                class="mx-auto block h-auto rounded-full"
                                width="100"
                            />
                            <img
                                v-else
                                src="https://www.pngkey.com/png/full/73-730477_first-name-profile-image-placeholder-png.png"
                                alt="Placeholder"
                                class="mx-auto block h-auto rounded-full"
                                width="100"
                            />
                        </a>
                    </div>

                    <div v-else>
                        <a :href="pcard.cap_url" target="_blank">
                            <img
                                src="https://www.pngkey.com/png/full/73-730477_first-name-profile-image-placeholder-png.png"
                                alt="Placeholder"
                                class="mx-auto block h-auto rounded-full"
                                width="100"
                            />
                        </a>
                    </div>

                    <div class="text-center leading-tigh pt-3 px-4">
                        <h1 class="text-xl text-center font-semibold">
                            <a
                                class="no-underline hover:underline text-center text-black"
                                :href="pcard.cap_url"
                                target="_blank"
                            >
                                {{ pcard.first_name }} {{ pcard.last_name }}
                            </a>
                        </h1>
                    </div>

                    <div class="text-center">
                        <h2 class="text-md text-center pb-4">
                            {{ pcard.rank.level }}
                        </h2>
                    </div>
                </div>
            </div>
        </div>

        <!-- Pagination -->
        <div class="flex justify-center items-center py-6">
            <template v-for="(link, index) in paginateProfiles" :key="index">
                <!-- For mobile, only show Previous and Next -->
                <Link
                    v-if="
                        link.label.toLowerCase().includes('previous') ||
                        link.label.toLowerCase().includes('next')
                    "
                    :href="link.url || ''"
                    v-html="link.label"
                    class="px-3 py-2 leading-tight text-gray-500 bg-white border rounded-lg border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                    :class="{
                        'font-bold text-red-800': link.active,
                    }"
                />

                <!-- For desktop and larger, show all pagination links -->
                <Link
                    v-else-if="
                        !link.label.toLowerCase().includes('previous') &&
                        !link.label.toLowerCase().includes('next')
                    "
                    :href="link.url || ''"
                    v-html="link.label"
                    class="hidden md:block px-3 py-2 leading-tight text-gray-500 bg-white border rounded-lg border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                    :class="{
                        'bg-white': !link.url,
                        'font-bold text-red-800': link.active,
                    }"
                />
            </template>
        </div>
    </div>
</template>
