<script setup>
import { ref, defineProps } from "vue";

const props = defineProps({
    featuredPubs: Object,
    spotlightPubs: Object,
});

const fPub = ref(props.featuredPubs.data);
const sPub = ref(props.spotlightPubs.data);
// const fPag = ref(props.featuredPubs.links);

const fPag = ref(props.featuredPubs.links || []);

const firstPageUrl = fPag.value?.[1]?.url;
const secondPageUrl = fPag.value?.[2]?.url;

// console.log(fPag);
</script>

<template>
    <div class="max-w-4xl mx-auto p-4 m-4">
        <div class="grid grid-cols-5">
            <h1 class="pl-2 col-span-4 text-lg font-medium">
                Our Publications
            </h1>
        </div>

        <div class="relative py-2">
            <div class="absolute inset-0 flex items-center">
                <div class="w-full border-b border-gray-600"></div>
            </div>
        </div>

        <div
            class="grid grid-cols-1 md:grid-cols-2 gap-2 py-10 place-items-center"
        >
            <div
                v-for="pub in sPub"
                :key="pub.id"
                class="w-full max-w-sm bg-white border border-gray-200 rounded-lg drop-shadow-md"
            >
                <div class="flex flex-col items-center pb-4">
                    <img
                        v-if="pub.img === null"
                        class="w-full h-40 drop-shadow-md object-cover rounded-t-lg"
                        src="https://placehold.co/600x500"
                        alt="Bonnie image"
                    />
                    <img
                        v-else
                        class="w-full h-40 drop-shadow-md object-cover rounded-t-lg"
                        :src="pub.img"
                        alt="pub image"
                    />
                    <h5
                        v-if="pub.short_title === null"
                        v-html="pub.title"
                        class="h-32 text-center text-xl font-medium text-gray-900 px-6 pt-3 my-3 overflow-y-auto"
                    ></h5>
                    <h5
                        v-else
                        v-html="pub.short_title"
                        class="h-32 text-center text-xl font-medium text-gray-900 px-6 pt-3 my-3 overflow-y-auto"
                    ></h5>
                    <span
                        v-if="pub.c_abstract === null"
                        v-html="pub.abstract"
                        class="scroll-visible text-md text-gray-500 text-left px-6 h-52 overflow-y-auto"
                    >
                    </span>
                    <span
                        v-else
                        v-html="pub.c_abstract"
                        class="scroll-visible text-md text-gray-500 text-left px-6 h-52 overflow-y-auto"
                    >
                    </span>
                    <a
                        :href="'https://pubmed.ncbi.nlm.nih.gov/' + pub.pmid"
                        target="_blank"
                        class="px-4 py-4 text-sm font-medium text-cardinal-red"
                    >
                        Read More
                    </a>
                </div>
            </div>
        </div>

        <div class="grid grid-cols-2 lg:grid-cols-3 gap-2 place-items-center">
            <div
                v-for="pub in fPub"
                :key="pub.id"
                class="w-full max-w-sm h-auto bg-white border border-gray-200 rounded-lg drop-shadow-md mb-10"
            >
                <div class="flex flex-col items-center pb-4">
                    <img
                        v-if="pub.img === null"
                        class="w-full h-28 drop-shadow-md object-cover rounded-t-lg"
                        src="https://placehold.co/600x500"
                        alt="Bonnie image"
                    />
                    <img
                        v-else
                        class="w-full h-28 drop-shadow-md object-cover rounded-t-lg"
                        :src="pub.img"
                        alt="pub image"
                    />
                    <h5
                        v-if="pub.short_title === null"
                        v-html="pub.title"
                        class="h-44 text-center text-lg font-medium text-gray-900 px-4 pt-6 mb-4 overflow-y-auto"
                    ></h5>
                    <h5
                        v-else
                        v-html="pub.short_title"
                        class="h-44 text-center text-lg font-medium text-gray-900 px-4 pt-6 mb-4 overflow-y-auto"
                    ></h5>
                    <span
                        v-if="pub.c_abstract === null"
                        v-html="pub.abstract"
                        class="scroll-visible text-md text-gray-500 text-left px-6 pb-4 h-36 overflow-y-auto"
                    >
                    </span>
                    <span
                        v-else
                        v-html="pub.c_abstract"
                        class="scroll-visible text-md text-gray-500 text-left px-6 pb-4 h-36 overflow-y-auto"
                    >
                    </span>

                    <a
                        :href="'https://pubmed.ncbi.nlm.nih.gov/' + pub.pmid"
                        class="text-sm font-medium text-cardinal-red pt-2"
                        target="_blank"
                    >
                        Read More
                    </a>
                </div>
            </div>
        </div>

        <!-- Pagination -->
        <div class="flex justify-center items-center pb-8">
            <Link
                v-if="firstPageUrl"
                :href="firstPageUrl"
                class="px-3 py-2 leading-tight text-gray-500 bg-white border rounded-lg border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                :class="{
                    ' bg-cardinal-red text-white': fPag.value?.[1]?.active,
                }"
            >
                1
            </Link>

            <Link
                v-if="secondPageUrl"
                :href="secondPageUrl"
                class="px-3 py-2 leading-tight text-gray-500 bg-white border rounded-lg border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                :class="{
                    ' bg-cardinal-red text-white': fPag.value?.[2]?.active,
                }"
            >
                2
            </Link>
        </div>
    </div>
</template>
<style>
.scroll-visible::-webkit-scrollbar {
    width: 5px;
}

.scroll-visible::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
}

.scroll-visible::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}
</style>
