<template>
    <transition name="modal">
        <div v-if="isVisible" class="modal-overlay" @click.self="closeModal">
            <div class="modal-content">
                <slot></slot>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        isVisible: Boolean,
    },
    emits: ["close"],
    methods: {
        closeModal() {
            this.$emit("close");
        },
    },
};
</script>

<style>
.modal-overlay {
    position: fixed;
    top: 52%;
    left: 50%;
    width: 1410px;
    height: 800px;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 1000;
    transform: translate(-50%, -50%);
}

.modal-content {
    background: transparent;
    padding: 20px;
    border-radius: 5px;
    transition: transform 0.5s;
    transform: scale(0.9);
}

.modal-enter-active,
.modal-leave-active {
    transition: opacity 0.5s;
}

.modal-enter,
.modal-leave-to {
    opacity: 0;
}

.modal-enter-active .modal-content {
    transform: scale(1);
}
</style>
