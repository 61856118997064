<script setup>
import { ref, onMounted } from "vue";
import { Link, router } from "@inertiajs/vue3";
import DefaultLayout from "@/Layouts/DefaultLayout.vue";

const props = defineProps(["pubs", "query"]);
const query = ref(props.query);

const publications = ref(props.pubs);
const pubData = publications.value.data;
const pubValues = publications.value;
const pubPaginate = ref(pubValues.links);

const keyword = ref("");
const enteredKeywords = ref([]);

const highlightKeyword = (title) => {
    const keywords = enteredKeywords.value;
    let highlightedTitle = title;

    for (const kw of keywords) {
        const regex = new RegExp(kw, "gi");
        highlightedTitle = highlightedTitle.replace(
            regex,
            (match) => `<span class="highlight">${match}</span>`
        );
    }

    return highlightedTitle;
};

const addKeyword = () => {
    if (keyword.value.trim() !== "") {
        enteredKeywords.value.push(keyword.value.trim());
        keyword.value = "";
        saveKeywords();
    }
};

const removeKeyword = (index) => {
    enteredKeywords.value.splice(index, 1);
    saveKeywords();
};

const saveKeywords = () => {
    localStorage.setItem("keywords", JSON.stringify(enteredKeywords.value));
};

const loadKeywords = () => {
    const storedKeywords = localStorage.getItem("keywords");
    if (storedKeywords) {
        enteredKeywords.value = JSON.parse(storedKeywords);
    }
};

// Load keywords from local storage on component mount
onMounted(() => {
    loadKeywords();
});

const search = () => {
    const queryValue = enteredKeywords.value.join(" ");
    const query = queryValue.trim();

    let url = "";

    if (query) {
        url = `?query=${query}`;
    } else {
        // Reset to page 1 if there is no query
        url = "?page=1";
    }

    router.get(url, {
        onSuccess: (data) => {
            query.value = query;
            publications.value = data.pubs;
            pubData.value = data.pubs.data;
            pubPaginate.value = data.pubs.links;
        },
        onError: (error) => {
            console.error(error);
        },
    });
};

const getQueryUrl = (url) => {
    if (query.value) {
        return url ? `${url}&query=${query.value}` : "";
    } else {
        return url;
    }
};

// console.log(pubPaginate);
</script>

<template>
    <DefaultLayout class="bg-gray-100">
        <div class="flex p-6 bg-gray-100 max-w-5xl justify-left">
            <div class="p-12 bg-white border-b border-gray-400 shadow-md w-5/6">
                <h2 class="items-center text-gray-900 sm:text-md">
                    Build your topic of interest to search the publications: add
                    multiple terms or keywords to combine the search
                </h2>
                <br />

                <input
                    :value="keyword"
                    @input="keyword = $event.target.value"
                    type="text"
                    placeholder="Keywords"
                    class="w-3/4 h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:border-cardinal-red focus:outline-none focus:ring-0"
                />
                <button
                    @click="addKeyword"
                    class="w-1/4 h-10 px-3 text-base text-white bg-cardinal-red rounded-lg hover:caret-cardinal-red"
                >
                    Add Keywords
                </button>

                <br />
                <div class="flex">
                    <div
                        class="mt-4 w-3/4 h-32 border-2 rounded-lg border-gray-300 p-1 overflow-scroll"
                    >
                        <span
                            v-for="(kw, index) in enteredKeywords"
                            :key="index"
                            class="inline-flex items-center bg-gray-200 rounded-full px-3 py-1 text-sm font-medium text-gray-700 mr-2 mt-2 hover:text-cardinal-red"
                        >
                            {{ kw }}
                            <button
                                class="ml-2 text-gray-500 hover:text-cardinal-red focus:outline-none"
                                @click="removeKeyword(index)"
                            >
                                <svg
                                    class="w-4 h-4"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    ></path>
                                </svg>
                            </button>
                        </span>
                    </div>
                    <button
                        class="w-1/4 h-10 px-3 mt-24 text-base text-white bg-cardinal-red rounded-lg hover:caret-cardinal-red"
                        @click="search"
                    >
                        Search
                    </button>
                </div>
                <br />
                <div>
                    <h1 class="items-center text-gray-600 text-xl">
                        Publications
                    </h1>
                    <table
                        class="min-w-full mt-4 bg-white divide-y divide-gray-200"
                    >
                        <thead class="bg-gray-50">
                            <tr
                                class="px-6 py-12 p-6 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                            >
                                <!-- <th>Abstract</th> -->
                                <th>Title</th>
                                <th class="pl-4">pmid</th>
                                <th>year</th>
                            </tr>
                        </thead>

                        <tbody class="bg-white divide-y divide-gray-200">
                            <tr v-for="(x, index) in pubData" :key="index">
                                <!-- <td class="max-w-xl pl-2 py-2">
                                    <p v-html="highlightKeyword(x.abstract)" />
                                </td> -->
                                <td class="max-w-xl pl-2 py-2">
                                    <p v-html="highlightKeyword(x.title)" />
                                </td>
                                <a
                                    class="text-blue-600 underline hover:blue text-center"
                                    :href="
                                        'https://pubmed.ncbi.nlm.nih.gov/' +
                                        x.pmid
                                    "
                                    target="_blank"
                                    ><td class="w-24">{{ x.pmid }}</td></a
                                >
                                <td>{{ x.pub_year }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="flex justify-center items-center pt-8 pb-8">
                    <template v-for="(link, index) in pubPaginate" :key="index">
                        <Link
                            v-if="link.url"
                            :href="getQueryUrl(link.url)"
                            v-html="link.label"
                            class="px-3 py-2 leading-tight text-gray-500 bg-white border sm:rounded-lg border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                            :class="{
                                'bg-white': !link.url,
                                'font-bold text-red-800': link.active,
                            }"
                        />
                        <span
                            v-else-if="link.label === '...'"
                            v-html="link.label"
                            class="px-3 py-2 leading-tight text-gray-500 bg-white border sm:rounded-lg border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                        ></span>
                        <Link
                            v-else-if="
                                link.label.toLowerCase().includes('next')
                            "
                            :href="getQueryUrl(link.url)"
                            v-html="link.label"
                            class="px-3 py-2 leading-tight text-gray-500 bg-white border sm:rounded-lg border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                        />
                        <Link
                            v-else-if="link.label === '&laquo; Previous'"
                            :href="getQueryUrl(link.url)"
                            v-html="link.label"
                            class="px-3 py-2 leading-tight text-gray-500 bg-white border sm:rounded-lg border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                        />
                    </template>
                </div>
            </div>
        </div>
    </DefaultLayout>
</template>

<style>
.highlight {
    background-color: rgb(243, 209, 209);
    color: rgb(140, 21, 21);
    font-weight: bold;
}
.overflow-scroll {
    overflow: auto;
}
</style>
