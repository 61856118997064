<script setup>
import { ref } from "vue";
import { Link } from "@inertiajs/vue3";

import {
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
import {
    Bars3Icon,
    // NewspaperIcon,
    UserIcon,
    HomeIcon,
    UserGroupIcon,
    XMarkIcon,
} from "@heroicons/vue/24/outline";

// const sidebarOpen = ref(false);

const navigation = [
    { name: "Department", route: "department", icon: HomeIcon },
    { name: "Division", route: "division", icon: UserGroupIcon },
    { name: "Faculty", route: "faculty", icon: UserIcon },
];
</script>

<template>
    <div>
        <!-- Static sidebar for desktop -->
        <div
            class="container flex justify-end px-6 py-6 mx-auto items-center shadow-md bg-cardinal-red"
        >
            <nav class="md:flex grid grid-cols-1">
                <Link
                    v-for="item in navigation"
                    :key="item.name"
                    :href="route(item.route)"
                    :class="[
                        route().current(item.route)
                            ? 'bg-cardinal-red-darkest text-white'
                            : 'text-white hover:bg-cardinal-red-light hover:bg-opacity-75',
                        'group flex items-center rounded-md px-2 py-2 md:text-xl font-medium',
                    ]"
                >
                    <component
                        :is="item.icon"
                        class="flex-shrink-0 w-6 h-6 mr-3 text-cardinal-red-lightest"
                    />
                    {{ item.name }}
                </Link>
            </nav>
        </div>
        <div class="justify-center">
            <div class="bg-white" data-slot="header" />
            <main>
                <slot />
            </main>
        </div>
    </div>
</template>
