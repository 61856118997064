<template>
    <div class="bg-white">
        <header
            class="bg-red-800 text-white py-2 px-4 mb-4 flex items-center h-20"
        >
            <h1 class="text-xl font-normal">
                DoM Team Scientist Publications Demo
            </h1>
        </header>

        <div class="container mx-auto px-4">
            <ais-instant-search
                :search-client="searchClient"
                :index-name="indexName"
                :future="{ preserveSharedStateOnUnmount: true }"
            >
                <ais-stats />
                <ais-search-box />
                <div class="flex mt-6">
                    <div class="w-1/6 mr-4 mt-6">
                        <h3 class="text-base font-semibold mb-2">
                            Faculty Name
                        </h3>
                        <ais-refinement-list
                            attribute="faculty_name"
                            :sort-by="['count:desc']"
                            show-more
                            class="w-56"
                        />
                        <br />
                        <h3 class="text-base font-semibold mb-2">
                            Publication Year
                        </h3>
                        <ais-refinement-list
                            attribute="pub_year"
                            :sort-by="['name:desc']"
                            show-more
                            class="w-56"
                        />
                        <br />
                        <h3 class="text-base font-semibold mb-2">Journal</h3>
                        <ais-refinement-list
                            attribute="source"
                            :sort-by="['name:desc']"
                            show-more
                            searchable
                            label="Journal"
                            class="w-56"
                        />
                    </div>
                    <div class="flex-1 pl-4">
                        <ais-hits>
                            <template v-slot="{ items }">
                                <table class="w-full border-collapse">
                                    <thead>
                                        <tr>
                                            <th class="border py-2 bg-gray-200">
                                                Title
                                            </th>
                                            <th class="border bg-gray-200">
                                                Abstract
                                            </th>
                                            <th class="border bg-gray-200">
                                                Faculty Name
                                            </th>
                                            <th class="border bg-gray-200">
                                                Pub Date
                                            </th>
                                            <th class="border bg-gray-200">
                                                PMID
                                            </th>
                                            <th class="border bg-gray-200">
                                                Relevant/Useful
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody v-for="item in items" :key="item.id">
                                        <tr>
                                            <td
                                                class="w-36 border py-2 px-3 text-center"
                                            >
                                                <ais-snippet
                                                    :hit="item"
                                                    attribute="title"
                                                />
                                            </td>
                                            <td class="w-96 border py-4 px-3">
                                                <div
                                                    class="h-28 overflow-y-auto"
                                                >
                                                    <ais-snippet
                                                        :hit="item"
                                                        attribute="abstract"
                                                    />
                                                </div>
                                            </td>
                                            <td class="w-12 border text-center">
                                                <ais-highlight
                                                    :hit="item"
                                                    attribute="faculty_name"
                                                />
                                            </td>
                                            <td
                                                class="w-24 border py-2 px-1 text-center"
                                            >
                                                {{ item.pub_date }}
                                            </td>
                                            <td
                                                class="w-16 border py-1 text-center text-blue-700"
                                            >
                                                <a
                                                    :href="`https://pubmed.ncbi.nlm.nih.gov/${item.pmid}`"
                                                    target="_blank"
                                                    @click="
                                                        () =>
                                                            sendClickEvent(item)
                                                    "
                                                    >{{ item.pmid }}</a
                                                >
                                            </td>
                                            <td
                                                class="w-6 border py-1 text-center"
                                            >
                                                <button
                                                    class="p-2 rounded-lg"
                                                    :class="
                                                        clickedItems[
                                                            item.objectID
                                                        ]
                                                            ? 'bg-gray-400'
                                                            : 'bg-cardinal-red text-white hover:bg-cardinal-red-darkest'
                                                    "
                                                    @click="
                                                        () =>
                                                            sendConversion(item)
                                                    "
                                                    :disabled="
                                                        clickedItems[
                                                            item.objectID
                                                        ]
                                                    "
                                                >
                                                    Relevant/Useful
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </template>
                        </ais-hits>
                    </div>
                </div>
                <ais-pagination class="mt-8 text-center" />
            </ais-instant-search>
        </div>
    </div>
</template>

<script setup>
import { ref, unref, reactive } from "vue";
import algoliasearch from "algoliasearch/lite";
import "instantsearch.css/themes/algolia-min.css";
import { AisHighlight, AisSnippet, AisStats } from "vue-instantsearch/vue3/es";
import aa from "search-insights";

//for local testing use pub_events index, when pushing to prod use publications index
const indexName = "pub_events";
// const indexName = "publications";

//need to hide this in secrets later
const searchClient = algoliasearch(
    "7TYR3OB7N6",
    "bb717ad48308ddf66fa7de39cf490ea5"
);

// Initialize Algolia Insights & need to hide this in secrets later
aa("init", {
    appId: "7TYR3OB7N6",
    apiKey: "bb717ad48308ddf66fa7de39cf490ea5",
});

// Set the userToken
aa("setUserToken", "view-token");

// Clicked state map
const clickedItems = reactive({});

const sendClickEvent = (item) => {
    aa("clickedObjectIDsAfterSearch", {
        userToken: "click-user-token",
        index: indexName,
        eventName: "PMID Clicked",
        objectIDs: [item.objectID],
        positions: [item.__position],
        queryID: item.__queryID,
    });
};

const sendConversion = (item) => {
    if (!clickedItems[item.objectID]) {
        clickedItems[item.objectID] = true;
        aa("convertedObjectIDsAfterSearch", {
            userToken: "converted-user-token",
            authenticatedUserToken: "converted-user-token",
            index: indexName,
            eventName: "Useful Conversion",
            objectIDs: [item.objectID],
            queryID: item.__queryID,
        });
    }
};
</script>

<style>
body,
h1 {
    margin: 0;
    padding: 0;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Symbol";
}

em {
    background: cyan;
    font-style: normal;
}

.container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 1rem;
}

.search-panel {
    display: flex;
}

.search-panel__filters {
    flex: 1;
}

.search-panel__results {
    flex: 3;
}

.searchbox {
    margin-bottom: 2rem;
}

.ais-RefinementList-label {
    display: flex;
    align-items: center;
}

.ais-RefinementList-checkbox {
    margin-right: 8px;
}

.ais-RefinementList-count {
    margin-left: 8px; /* Space between faculty name and count */
    margin-bottom: 4px; /* Space below the count */
}

/* Show scrollbar */
.h-28.overflow-y-auto::-webkit-scrollbar {
    width: 12px; /* Adjust width for vertical scrollbar here */
}

.h-28.overflow-y-auto::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the scroll thumb */
    border-radius: 6px; /* Radius of the scroll thumb */
}

.h-28.overflow-y-auto::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color of the scroll thumb on hover */
}

.h-28.overflow-y-auto::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Color of the track */
    border-radius: 6px; /* Radius of the track */
}

/* Increased specificity for overriding default styles */
.ais-RefinementList .ais-RefinementList-showMore {
    background-color: #8c1515 !important; /* Force override with `!important` if necessary */
    color: #ffffff !important; /* Ensure text color is white */
}

/* For hovering effect */
.ais-RefinementList-showMore:hover {
    background-color: #490101 !important;
    color: white;
}
</style>
